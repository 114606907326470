/* eslint-disable react-hooks/exhaustive-deps */
import { useShowMemoToVendor } from '@melio/ap-widgets';
import { DeliveryMethod, usePayments } from '@melio/platform-api';
import { useConfig } from '@melio/platform-provider';
import { useCallback, useEffect, useState } from 'react';

import { ActionToPerform, getConditionalSteps, GetConditionalStepType } from '../utils/scheduleFlowStepsUtils';
import { SinglePaymentFlowActivityProps, SinglePaymentFlowActivityStep } from './types';

const initialStep: SinglePaymentFlowActivityStep = 'FUNDING_SOURCE_SELECTION';
const lastStep: SinglePaymentFlowActivityStep = 'PAYMENT_SCHEDULED';

export type GetSinglePaymentConditionalStepsCallback = Partial<GetConditionalStepType>;

export type UseSinglePaymentFlowActivityStepArgs = {
  onFirstStepBack: SinglePaymentFlowActivityProps['onBack'];
  actionToPerform: ActionToPerform;
  deliveryMethodIdFromState?: string;
  selectedDeliveryMethod?: DeliveryMethod | null;
  isFinancingSelected?: boolean;
  hasInvoice?: boolean;
  vendorId?: string;
};
export const useSinglePaymentFlowActivityStep = ({
  onFirstStepBack,
  actionToPerform,
  deliveryMethodIdFromState,
  selectedDeliveryMethod,
  isFinancingSelected = false,
  vendorId,
  hasInvoice,
}: UseSinglePaymentFlowActivityStepArgs) => {
  const [currentStep, setCurrentStep] = useState<SinglePaymentFlowActivityStep>(initialStep);
  const [isDeliveryMethodSelectionStepVisited, setIsDeliveryMethodSelectionStepVisited] = useState<boolean>(false);
  const { shouldDisplayMemoToVendor } = useShowMemoToVendor();
  const { settings } = useConfig();

  const { data: paymentsData } = usePayments({
    enabled: !!vendorId || hasInvoice,
    params: { search: { 'vendor.id': vendorId } },
  });

  useEffect(() => {
    if (currentStep === 'DELIVERY_METHOD_SELECTION') {
      setIsDeliveryMethodSelectionStepVisited(true);
    }
  }, [currentStep]);

  const getConditionalStepsCallback = useCallback(
    (props: GetSinglePaymentConditionalStepsCallback = {}) =>
      getConditionalSteps({
        actionToPerform,
        isDeliveryMethodSelectionStepVisited,
        deliveryMethod: selectedDeliveryMethod,
        deliveryMethodIdFromState,
        isFinancingSelected,
        shouldDisplayMemoToVendor,
        vendorId,
        hasInvoice,
        paymentsData,
        enablePromoteAch: settings.promoteAchDeliveryMethod,
        ...props,
      }),
    [
      isDeliveryMethodSelectionStepVisited,
      actionToPerform,
      selectedDeliveryMethod,
      deliveryMethodIdFromState,
      isFinancingSelected,
      shouldDisplayMemoToVendor,
    ]
  );

  const goToPreviousStep = (): void => {
    if (currentStep === initialStep) {
      return onFirstStepBack();
    }

    if (currentStep === lastStep) {
      // shouldn't happen
      return;
    }

    const {
      hasDeliveryMethodStep,
      hasMemoToVendorStep,
      hasPaymentPurposeStep,
      hasRepaymentTermsStep,
      hasSelectDeliveryDateStep,
    } = getConditionalStepsCallback();

    const getSelectDeliveryDatePrevStep = () => {
      if (hasPaymentPurposeStep) {
        return 'PAYMENT_PURPOSE';
      }
      if (hasDeliveryMethodStep) {
        return 'DELIVERY_METHOD_SELECTION';
      }
      return 'FUNDING_SOURCE_SELECTION';
    };

    const getMemoToVendorPrevStep = () => {
      if (hasRepaymentTermsStep) {
        if (hasDeliveryMethodStep) {
          return 'DELIVERY_METHOD_SELECTION';
        }
        return 'REPAYMENT_TERMS';
      }

      if (hasSelectDeliveryDateStep) {
        return 'DELIVERY_DATE_SELECTION';
      }
      return getSelectDeliveryDatePrevStep();
    };

    const getReviewAndConfirmPrevStep = () => {
      if (hasMemoToVendorStep) {
        return 'MEMO_TO_VENDOR';
      }

      if (hasSelectDeliveryDateStep) {
        return 'DELIVERY_DATE_SELECTION';
      }
      return getSelectDeliveryDatePrevStep();
    };

    const previousStepMap: Omit<
      Record<SinglePaymentFlowActivityStep, SinglePaymentFlowActivityStep>,
      typeof initialStep | typeof lastStep
    > = {
      DELIVERY_METHOD_SELECTION: 'FUNDING_SOURCE_SELECTION',
      PAYMENT_PURPOSE: hasDeliveryMethodStep ? 'DELIVERY_METHOD_SELECTION' : 'FUNDING_SOURCE_SELECTION',
      DELIVERY_DATE_SELECTION: getSelectDeliveryDatePrevStep(),
      MEMO_TO_VENDOR: getMemoToVendorPrevStep(),
      REVIEW_AND_CONFIRM: getReviewAndConfirmPrevStep(),
      COMPLETE_LEGAL_INFO: 'REVIEW_AND_CONFIRM',
      REPAYMENT_TERMS: 'FUNDING_SOURCE_SELECTION',
    };

    setCurrentStep(previousStepMap[currentStep]);
  };

  const removeStep = (step: SinglePaymentFlowActivityStep, steps: SinglePaymentFlowActivityStep[]) => {
    const index = steps.indexOf(step);
    if (index !== -1) {
      steps.splice(index, 1);
    }
  };

  const calculateStepNumber = (currentStep: SinglePaymentFlowActivityStep): number => {
    const DEFAULT_STEPS_ORDER: SinglePaymentFlowActivityStep[] = [
      'FUNDING_SOURCE_SELECTION',
      'REPAYMENT_TERMS',
      'DELIVERY_METHOD_SELECTION',
      'PAYMENT_PURPOSE',
      'DELIVERY_DATE_SELECTION',
      'MEMO_TO_VENDOR',
      'REVIEW_AND_CONFIRM',
    ];

    const {
      hasDeliveryMethodStep,
      hasMemoToVendorStep,
      hasPaymentPurposeStep,
      hasRepaymentTermsStep,
      hasSelectDeliveryDateStep,
    } = getConditionalStepsCallback();

    if (!hasDeliveryMethodStep) {
      removeStep('DELIVERY_METHOD_SELECTION', DEFAULT_STEPS_ORDER);
    }
    if (!hasPaymentPurposeStep) {
      removeStep('PAYMENT_PURPOSE', DEFAULT_STEPS_ORDER);
    }
    if (!hasRepaymentTermsStep) {
      removeStep('REPAYMENT_TERMS', DEFAULT_STEPS_ORDER);
    }
    if (!hasMemoToVendorStep) {
      removeStep('MEMO_TO_VENDOR', DEFAULT_STEPS_ORDER);
    }
    if (!hasSelectDeliveryDateStep) {
      removeStep('DELIVERY_DATE_SELECTION', DEFAULT_STEPS_ORDER);
    }

    return DEFAULT_STEPS_ORDER.indexOf(currentStep) + 1;
  };

  return {
    currentStep,
    getConditionalStepsCallback,
    goToStep: setCurrentStep,
    goToPreviousStep,
    calculateStepNumber,
  };
};
