import { Group, SectionBanner, useFormSubmissionController } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { Account } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useOneTimeEffect } from '@melio/platform-utils';
import { useCallback } from 'react';

import { TermsAndConditionsLabel } from '../../../components';
import { NewSinglePaymentStepLayout } from '../../../layouts';
import {
  CompleteRequiredDetailsForm,
  CompleteRequiredDetailsFormFields,
  CtaClickIntent,
  MissingField,
} from '../CompleteRequiredDetailsForm';
import { CalculateGracePeriodStatus, getRequiredFields } from '../utils';

export type CompleteRequiredDetailsScreenProps = {
  missingFields: MissingField[];
  isSaving: boolean;
  isLoading: boolean;
  submitButtonLabel: string;
  onBack?: VoidFunction;
  onClose: (props?: { isSubmitAllMissingFields: boolean }) => void;
  onSkip?: VoidFunction;
  onDone: (data: CompleteRequiredDetailsFormFields) => void;
  account: Account | undefined;
  isSkippable: boolean;
  isDismissible: boolean;
  setIsDismissAllowed: (isDismissAllowed: boolean) => void;
  error?: string;
  isGracePeriodStarted?: boolean;
  isGracePeriodEnded?: boolean;
  numberOfPaymentsLeftInGracePeriod?: number | null;
  placement: 'complete-required-details' | 'one-last-thing';
};

export const CompleteRequiredDetailsScreen = withAnalyticsContext<CompleteRequiredDetailsScreenProps>(
  ({
    setAnalyticsProperties,
    onClose,
    onBack,
    isLoading,
    missingFields,
    account,
    onDone,
    isSaving,
    onSkip,
    isSkippable,
    isDismissible,
    setIsDismissAllowed,
    error,
    submitButtonLabel,
    isGracePeriodStarted,
    isGracePeriodEnded,
    numberOfPaymentsLeftInGracePeriod,
    placement,
  }) => {
    const { formatMessage } = useMelioIntl();
    const { track } = useAnalytics();
    const {
      submitButtonProps: { onClick: submitButtonOnClick, ...pickedSubmitButtonProps } = {},
      onSubmissionStateChange,
    } = useFormSubmissionController<CompleteRequiredDetailsFormFields>();

    const isMissingFields = !!missingFields.length;
    const emptyState = !isMissingFields && !error && !isLoading;

    const { requiredFields, numberOfRequiredUserFields, numberOfRequiredOrgFields } = getRequiredFields(missingFields);

    const analyticsTrackerCommon = {
      GracePeriodStatus: CalculateGracePeriodStatus(isGracePeriodStarted, isGracePeriodEnded),
      // GracePeriodDaysRemain: '', // TODO: should complete on phase 2
      GracePeriodPaymentsRemain: numberOfPaymentsLeftInGracePeriod,
      NumRequiredUserFields: numberOfRequiredUserFields,
      NumRequiredOrgFields: numberOfRequiredOrgFields,
      RequiredFields: requiredFields,
      SkippableRemindLater: !emptyState && onSkip && isDismissible,
      SkippableXButton: isDismissible,
    };

    setAnalyticsProperties(analyticsTrackerCommon);

    useOneTimeEffect(
      () => {
        track('Organization', 'View');
      },
      () => !isLoading,
      [track, isLoading]
    );

    const onEmptyStateRedirectClick = () => {
      onClose({ isSubmitAllMissingFields: true });
    };

    const handleClick = useCallback(
      (intent: CtaClickIntent, ctaLabel: string, callback?: VoidFunction) => {
        track('Organization', 'Click', {
          Intent: intent,
          Cta: ctaLabel,
        });
        callback?.();
      },
      [track]
    );

    const titleLabels = {
      title:
        placement === 'complete-required-details'
          ? formatMessage('widgets.completeRequiredDetailsModal.header', {
              name: account?.company.name,
              length: String(account?.company.name?.length ?? 0),
            })
          : formatMessage('activities.completeLegalInfo.screens.completeLegalInfo.title'),
      description:
        placement === 'complete-required-details'
          ? formatMessage('widgets.completeRequiredDetailsModal.description')
          : formatMessage('activities.completeLegalInfo.screens.completeLegalInfo.description', {
              companyName: account?.company.name,
            }),
    };

    return (
      <NewSinglePaymentStepLayout
        isLoading={isLoading}
        headerContent={
          <NewSinglePaymentStepLayout.Header>
            {onBack && <NewSinglePaymentStepLayout.BackButton onClick={onBack} />}
            {isDismissible && (
              <NewSinglePaymentStepLayout.CloseButton
                onClick={() => handleClick('skip-details-submission', 'close', onClose)}
              />
            )}
          </NewSinglePaymentStepLayout.Header>
        }
      >
        <Group variant="vertical" spacing="s">
          <Group.Item>
            <NewSinglePaymentStepLayout.Title>{titleLabels.title}</NewSinglePaymentStepLayout.Title>
          </Group.Item>
          <Group.Item>
            <NewSinglePaymentStepLayout.Description>{titleLabels.description}</NewSinglePaymentStepLayout.Description>
          </Group.Item>
        </Group>
        <NewSinglePaymentStepLayout.Content>
          {isMissingFields && account && (
            <CompleteRequiredDetailsForm
              onSubmissionStateChange={onSubmissionStateChange}
              onSubmit={onDone}
              trackClick={handleClick}
              missingFields={missingFields}
              isLoading={isLoading}
              isSaving={isSaving}
              isSkippable={isSkippable}
              setIsDismissAllowed={setIsDismissAllowed}
              account={account}
              ctaLabel={submitButtonLabel}
            />
          )}
          {emptyState && (
            <SectionBanner
              variant="success"
              description={formatMessage('widgets.completeRequiredDetailsModal.message.no-required-fields')}
            />
          )}
          {error && <SectionBanner variant="critical" description={error} />}
        </NewSinglePaymentStepLayout.Content>
        <Group variant="vertical" alignItems="center" spacing="l">
          {isMissingFields && (
            <Group variant="vertical" alignItems="center" spacing="s">
              <TermsAndConditionsLabel actionLabel={submitButtonLabel} />
              <NewSinglePaymentStepLayout.NextButton
                {...pickedSubmitButtonProps}
                onClick={() => handleClick('submit-details', submitButtonLabel, submitButtonOnClick)}
                isLoading={isSaving}
                isFullWidth
                label={submitButtonLabel}
              />
            </Group>
          )}
          {((!emptyState && onSkip && isDismissible) || error) && (
            <NewSinglePaymentStepLayout.NakedNextButton
              onClick={() => handleClick('skip-details-submission', 'remind-me-later', onSkip)}
              label={formatMessage('widgets.completeRequiredDetailsModal.button.dismiss.label')}
            />
          )}
          {emptyState && (
            <NewSinglePaymentStepLayout.NakedNextButton
              onClick={() => handleClick('submit-details', 'continue', onEmptyStateRedirectClick)}
              label={formatMessage('widgets.completeRequiredDetailsModal.button.empty-state.redirect.label')}
            />
          )}
        </Group>
      </NewSinglePaymentStepLayout>
    );
  }
);
