import { SystemMessage, useIsMobile, useIsTestMode, useMelioIntl } from '@melio/ar-domain';
import { Group, Icon, Layout, NakedButton, SectionBanner, TabItem as DSTabItem, Tabs } from '@melio/penny';
import { CompleteMandatoryDetailsWarningBanner, ComplianceVerificationFailedBanner } from '@melio/platform-kyc';
import { PageTitle } from '@melio/platform-utils';
import { Outlet } from 'react-router-dom';

import { HeaderToolBar, HeaderToolBarMobile } from './components';

export type TabsLayoutProps<T extends string = string> = {
  tabs: TabItem<T>[];
  activeTab: T;
  onChange: (name: T) => void;
  onCreateInvoice: VoidFunction;
  onCreateCustomer: VoidFunction;
  onVisitSupportSettingsPage: VoidFunction;
  onActivateAccount?: VoidFunction;
  kycBannerVariation?: 'complete-details' | 'verification-failed';
  onCompleteKycDetails?: VoidFunction;
};

export function TabsLayout<T extends string = string>({
  activeTab,
  onChange,
  tabs,
  onCreateInvoice,
  onCreateCustomer,
  onVisitSupportSettingsPage,
  onActivateAccount,
  kycBannerVariation,
  onCompleteKycDetails,
  ...props
}: TabsLayoutProps<T>) {
  const { formatMessage } = useMelioIntl();
  const isMobile = useIsMobile();
  const isTestMode = useIsTestMode();

  return (
    <Layout backgroundColor="white" data-component={TabsLayout.displayName} {...props}>
      <Group variant="vertical" spacing="l">
        <Group variant="vertical" spacing="s">
          {isTestMode && (
            <SectionBanner
              data-testid="test-mode-banner"
              description={formatMessage('ar.dashboard.activities.tabsLayout.testModeBanner.description')}
              variant="warning"
              leftElement={<Icon type="notification" size="small" />}
              isCompact={!isMobile}
              action={
                <NakedButton
                  onClick={onActivateAccount}
                  variant="secondary"
                  label={formatMessage('ar.dashboard.activities.tabsLayout.testModeBanner.action.label')}
                  data-testid="activate-account-button"
                />
              }
            />
          )}
          <SystemMessage />
        </Group>
        <Group variant="vertical" spacing="m">
          {kycBannerVariation === 'complete-details' && onCompleteKycDetails ? (
            <CompleteMandatoryDetailsWarningBanner onOpenCompleteRequiredDetailsDialog={onCompleteKycDetails} />
          ) : kycBannerVariation === 'verification-failed' ? (
            <ComplianceVerificationFailedBanner />
          ) : null}
          <Group variant="vertical">
            <Group spacing="m" variant="horizontal" justifyContent="space-between">
              <PageTitle textStyle="heading1Semi">
                {formatMessage('ar.dashboard.activities.tabsLayout.title.label')}
              </PageTitle>
              {isMobile ? (
                <HeaderToolBarMobile
                  onCreateInvoice={onCreateInvoice}
                  onCreateCustomer={onCreateCustomer}
                  onVisitSupportSettingsPage={onVisitSupportSettingsPage}
                />
              ) : (
                <HeaderToolBar
                  onCreateInvoice={onCreateInvoice}
                  onVisitSupportSettingsPage={onVisitSupportSettingsPage}
                />
              )}
            </Group>
            <Tabs
              aria-label={formatMessage('ar.dashboard.activities.tabsLayout.tabs.aria-label')}
              tabs={tabs}
              activeTab={activeTab}
              onChange={onChange as never}
            />
            <Outlet />
          </Group>
        </Group>
      </Group>
    </Layout>
  );
}
TabsLayout.displayName = 'TabsLayout';

export type TabItem<T = string> = Override<DSTabItem, { name: T }>;
