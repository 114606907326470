import { Container, FormSelectNewOption, Group, RadioGroup, SelectNew } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { FeeCatalog, FundingSource, FundingSourceType } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useEffect, useState } from 'react';

import { PaymentFlowOnChangeHandlers } from '../../../../types';
import { RadioLabelWithTooltip } from '../util/RadioLabelWithTooltip';
import { FundingSourceCard } from './FundingSourceCard/FundingSourceCard';
import { useFundingSourcesSearchBar } from './useFundingSourceSearchBar/useFundingSourceSearchBar';

type FundingSourceSectionProps = {
  fundingSourceId: string | undefined;
  fundingSources: FundingSource[];
  onFundingSourceChange: PaymentFlowOnChangeHandlers['onFundingSourceChange'];
  isLoading: boolean;
  isReadOnly: boolean;
  cardDisabledMessage: string | undefined;
  feeCatalog?: FeeCatalog[];
};

export const FundingSourceSection = ({
  fundingSourceId,
  fundingSources,
  onFundingSourceChange,
  isLoading,
  isReadOnly,
  cardDisabledMessage,
  feeCatalog,
}: FundingSourceSectionProps) => {
  const { track } = useAnalytics();
  const { formatMessage } = useMelioIntl();
  const [type, setType] = useState<FundingSource['type']>();

  const fundingSourcesByType = fundingSources.filter((fundingSource) => fundingSource.type === type);
  const selectedFundingSource = fundingSources.find((fundingSource) => fundingSource.id === fundingSourceId);

  const {
    props: searchProps,
    show: showSearch,
    filtered: filteredFundingSources,
  } = useFundingSourcesSearchBar(fundingSourcesByType);

  const options = fundingSourcesByType.map(toOption);
  const filteredOptions = filteredFundingSources.map(toOption);
  const selectedOption = selectedFundingSource && toOption(selectedFundingSource);

  useEffect(() => {
    setType(selectedFundingSource?.type);
  }, [selectedFundingSource]);

  const isFundingSourceDisabled = (fundingSourceType: FundingSourceType) =>
    !fundingSources.find((fundingSource) => fundingSource.type === fundingSourceType);

  const createTypeId = (type: FundingSource['type']) => `${type}-option`;
  const isCardDisabled = isFundingSourceDisabled(FundingSourceType.Card);
  const isBankDisabled = isFundingSourceDisabled(FundingSourceType.BankAccount);

  return (
    <Group variant="vertical" spacing="xs">
      <RadioGroup
        data-testid="funding-source-type"
        variant="horizontal"
        value={type}
        isReadOnly={isReadOnly}
        onChange={(event) => {
          const existingFundingSource = fundingSources.find(
            (fundingSource) => fundingSource.type === event.target.value
          );
          if (existingFundingSource) {
            track('PaymentMethod', 'Click', {
              Intent: 'set-payment-method',
              Cta: `${existingFundingSource.type}-radio-button`,
              PaymentMethodId: existingFundingSource.id,
            });
            setType(existingFundingSource.type);
            onFundingSourceChange(existingFundingSource.id);
          }
        }}
        options={[
          {
            value: FundingSourceType.BankAccount,
            mainLabelProps: {
              label: formatMessage('activities.paymentFlow.form.content.fundingSourceCard.bank-account.type'),
            },
            id: createTypeId(FundingSourceType.BankAccount),
            ariaLabel: formatMessage('activities.paymentFlow.form.content.fundingSourceCard.bank-account.type.aria'),
            disabled: {
              isDisabled: isBankDisabled,
            },
          },
          {
            value: FundingSourceType.Card,
            label: (
              <RadioLabelWithTooltip
                label={formatMessage('activities.paymentFlow.form.content.fundingSourceCard.card.type')}
                isReadOnly={isReadOnly}
                isDisabled={isCardDisabled}
                tooltip={cardDisabledMessage}
                dataTestId="fundingSource-disabled-indicator"
              />
            ),
            id: createTypeId(FundingSourceType.Card),
            ariaLabel: formatMessage('activities.paymentFlow.form.content.fundingSourceCard.card.type.aria'),
            disabled: {
              isDisabled: isCardDisabled,
            },
          },
        ]}
      />
      <SelectNew
        isReadOnly={isReadOnly}
        aria-describedby={type ? createTypeId(type) : undefined}
        aria-labelledby="pay-from"
        data-testid="funding-source-dropdown"
        value={selectedOption?.value}
        options={options}
        valueRenderer={(option) => <ValueRenderer {...option} feeCatalog={feeCatalog} />}
        optionRenderer={OptionRenderer}
        searchBarProps={showSearch ? { ...searchProps, options: filteredOptions } : undefined}
        onChange={(event) => {
          const fundingSource = event.target.value as unknown as FundingSource;
          track('PaymentMethod', 'Click', {
            Intent: 'set-payment-method',
            Cta: fundingSource.type,
            PaymentMethodId: fundingSource.id,
          });
          onFundingSourceChange(fundingSource.id);
        }}
        size="large"
        isLoading={isLoading}
        shouldHideClearButton
      />
    </Group>
  );
};

type OptionValueRendererProps = FormSelectNewOption<FundingSource> & { feeCatalog?: FeeCatalog[] };

const ValueRenderer = (props: OptionValueRendererProps) => (
  <Container width="full" paddingY="s" data-testid={`funding-source-dropdown-selected-${props.value.id}`}>
    <FundingSourceCard fundingSource={props.value} feeCatalog={props.feeCatalog} />
  </Container>
);
const OptionRenderer = (props: OptionValueRendererProps) => (
  <Container width="full" data-testid={`funding-source-dropdown-item-${props.value.id}`}>
    <FundingSourceCard fundingSource={props.value} />
  </Container>
);

const toOption = (fundingSource: FundingSource): OptionValueRendererProps => ({
  value: fundingSource,
  label: fundingSource.displayName,
});
