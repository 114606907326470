import { CompleteRequiredDetailsFormFields, MissingField } from './CompleteRequiredDetailsForm/types';

const PRE_GRACE_PERIOD = 'pre-grace-period';
const IN_GRACE_PERIOD = 'in-grace-period';
const POST_GRACE_PERIOD = 'post-grace-period';

export const CalculateGracePeriodStatus = (isGracePeriodStarted?: boolean, isGracePeriodEnded?: boolean) => {
  if (!isGracePeriodStarted) {
    return PRE_GRACE_PERIOD;
  }
  if (!isGracePeriodEnded) {
    return IN_GRACE_PERIOD;
  }
  return POST_GRACE_PERIOD;
};

const FieldEntityToFieldTypeMapper: Record<MissingField['entity'], 'organization' | 'user'> = {
  company: 'organization',
  user: 'user',
};
const convertToAnalyticsField = (field: MissingField) => ({
  FieldName: field.name,
  FieldType: FieldEntityToFieldTypeMapper[field.entity],
  FieldMandatory: !!field.isRequired,
});

export const getRequiredFields = (missingFields: MissingField[]) => {
  const numberOfRequiredOrgFields = missingFields.filter((item) => item.entity == 'company').length;
  const numberOfRequiredUserFields = missingFields.filter((item) => item.entity == 'user').length;
  const requiredFields = missingFields.map(convertToAnalyticsField);

  return {
    numberOfRequiredOrgFields,
    numberOfRequiredUserFields,
    requiredFields,
  };
};

export const getFilledFields = (missingFields: MissingField[], data: CompleteRequiredDetailsFormFields) => {
  const filledUserFields = missingFields.filter((field) => field.entity === 'user' && !!data[field.name]);
  const filledOrgFields = missingFields.filter((field) => field.entity === 'company' && !!data[field.name]);

  return {
    numFilledUserFields: filledUserFields.length,
    numFilledOrgFields: filledOrgFields.length,
    filledFields: [...filledUserFields, ...filledOrgFields].map(convertToAnalyticsField),
  };
};
