import { ComponentThemeType } from '@/cl/theme/theme.hooks';

export const LabelCopyComponentTheme: ComponentThemeType<{ align: 'center' | 'left'; direction: 'column' | 'row' }> = (
  currentTheme,
  { align, direction },
) => ({
  baseStyle: {
    container: {
      display: 'flex',
      alignItems: 'center',
      borderRadius: currentTheme.borderRadii.global['100'],
      p: '8px',
      gridGap: '16px',
      flexDirection: direction,
      ...(direction === 'column' ? { alignItems: 'flex-start', gridGap: 'm' } : {}),
    },
    link: {
      ...(direction === 'column' ? { width: 'full' } : {}),
    },
  },
  variants: {
    button: {
      container: {
        justifyContent: 'space-between',
      },
    },
    icon: {
      container: {
        justifyContent: align === 'left' ? 'flex-start' : 'center',
      },
    },
  },
});
