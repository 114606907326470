import { Card, Group, StatusIconSolid, Table, TableColumnDef, Text, Tooltip, useTable } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

import { DeliveryMethodChangeMobileTable } from './DeliveryMethodChangeMobileTable';
import { DeliveryMethodChangeTableData } from './types';
import { formatDate } from './utils';

export const DeliveryMethodChangeTable = ({
  deliveryMethodChangeTableData,
}: {
  deliveryMethodChangeTableData: DeliveryMethodChangeTableData[];
}) => {
  const { formatCurrency, formatMessage } = useMelioIntl();
  const columns: TableColumnDef<DeliveryMethodChangeTableData>[] = [
    {
      id: 'vendorName',
      header: formatMessage('activities.paymentFlow.form.content.deliveryMethodChangedModal.table.vendor'),
      size: 's',
      cell: ({ row, rowIndex }) => (
        <Table.Cell size={1} data-testid={`vendor-${rowIndex}-name`}>
          <Group width="full" variant="vertical" spacing="xxs">
            <Text shouldSupportEllipsis textStyle="body3Semi" color="inherit">
              {row.vendor?.name}
            </Text>
            <Text shouldSupportEllipsis textStyle="body4" color="global.neutral.900">
              {row.invoiceNumber
                ? row.invoiceNumber
                : formatMessage('activities.paymentFlow.form.content.deliveryMethodChangedModal.noInvoice')}
            </Text>
          </Group>
        </Table.Cell>
      ),
    },
    {
      id: 'debitOn',
      size: 's',
      header: formatMessage('activities.paymentFlow.form.content.deliveryMethodChangedModal.table.debit-on'),
      cell: ({ row }) => (
        <Table.Cell data-testid="catalog-item-price">
          <Group width="full" variant="vertical" spacing="xxs">
            <Text shouldSupportEllipsis textStyle="body3" color="inherit">
              {formatDate(row.scheduleDate.value)}
            </Text>
            {!!row.scheduleDate.tooltipText && (
              <Group alignItems="center">
                <Text textStyle="body4">
                  {formatMessage(
                    'activities.paymentFlow.form.content.deliveryMethodChangedModal.table.debit-date-updates'
                  )}
                </Text>
                <Tooltip isEnabled={!!row.scheduleDate.tooltipText} content={row.scheduleDate.tooltipText}>
                  <StatusIconSolid variant="warning" size="small" data-testid="icon-indicator" aria-hidden />
                </Tooltip>
              </Group>
            )}
          </Group>
        </Table.Cell>
      ),
    },
    {
      id: 'deliverBy',
      size: 's',
      header: formatMessage('activities.paymentFlow.form.content.deliveryMethodChangedModal.table.debit-by'),
      cell: ({ row }) => (
        <Table.Cell data-testid="catalog-item-price">
          <Group width="full" variant="vertical" spacing="xxs">
            <Text shouldSupportEllipsis textStyle="body3" color="inherit">
              {formatDate(row.deliveryDate.value)}
            </Text>
            {!!row.deliveryDate.tooltipText && (
              <Group alignItems="center">
                <Text textStyle="body4">
                  {formatMessage(
                    'activities.paymentFlow.form.content.deliveryMethodChangedModal.table.delivery-date-updates'
                  )}
                </Text>
                <Tooltip isEnabled={!!row.deliveryDate.tooltipText} content={row.deliveryDate.tooltipText}>
                  <StatusIconSolid variant="warning" size="small" data-testid="icon-indicator" aria-hidden />
                </Tooltip>
              </Group>
            )}
          </Group>
        </Table.Cell>
      ),
    },
    {
      id: 'paymentAmount',
      size: 's',
      header: formatMessage('activities.paymentFlow.form.content.deliveryMethodChangedModal.table.payment-amount'),
      cell: ({ row }) => (
        <Table.Cell data-testid="catalog-item-price">
          <Text shouldSupportEllipsis textStyle="body3Semi" color="inherit">
            {formatCurrency(Number(row.amount))}
          </Text>
        </Table.Cell>
      ),
    },
  ];

  const tableProps = useTable({
    data: deliveryMethodChangeTableData,
    columns,
    headerVariant: 'dark',
    mobileRowRenderer: (rowData) => <DeliveryMethodChangeMobileTable row={rowData} />,
  });

  return (
    <Card paddingX="none" paddingY="none" variant="flat">
      <Table {...tableProps} data-testid="delivery-changed-modal-table" />
    </Card>
  );
};
