import { union } from 'lodash';
import { useRecoilState, useRecoilValue } from 'recoil';
import { PaymentStatusEnum } from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';

import { GroupItemCard, PayCardTagStatus } from '@/cl/components/PayCard/PayCard.component';
import {
  payDashboardItemSelectionsSelector,
  payDashboardQuerySearchSelector,
} from '@/store/PayDashboard/PayDashboards.model';
import { usePlatformIntl } from '@/translations/Intl';
import { GroupItemType } from '@/types/payDashboard.types';
import { getFinalPaymentTag, getPaymentCardRecurringText, isLastPayment } from '@/utils/billSubscription.utils';
import { BasePaymentCardProps } from '@/widgets/pay-dashboard/cards/types/PaymentCard.types';
import { useTrackBatchSelection } from '../useTrackBatchSelection';

export const FailedPaymentPayCard = ({ payment, vendorName }: BasePaymentCardProps) => {
  const { formatMessage, formatDate } = usePlatformIntl();
  const highlightedText = useRecoilValue(payDashboardQuerySearchSelector);
  const [itemSelections, setItemSelections] = useRecoilState(payDashboardItemSelectionsSelector);
  const [isRecurringPaymentsEnabled] = useDevFeature(FeatureFlags.RecurringPayments, false);

  const firstBill = payment.bills?.[0];
  const dueDate = firstBill?.dueDate;
  const { scheduledDate, subscriptionOccurrence } = payment;

  const { trackBatchSelection } = useTrackBatchSelection();

  const handleItemChecked = (checked: boolean) => {
    trackBatchSelection({
      PaymentId: payment.id,
      VendorId: firstBill?.vendorId,
      Amount: payment.amount,
      DueDate: dueDate,
      selected: itemSelections.length,
      action: checked ? 'add' : 'remove',
    });

    setItemSelections((currVal) =>
      checked ? union(currVal, [payment.id]) : currVal.filter((id) => id !== payment.id),
    );
  };

  const getTagByType = (): { tagText: string; status: PayCardTagStatus } | null => {
    if (payment.status === PaymentStatusEnum.Failed) {
      return {
        tagText: formatMessage('widgets.payCard.badgeByType.failed'),
        status: 'critical',
      };
    }
    if (recurringText && isLastPayment(subscriptionOccurrence)) {
      return getFinalPaymentTag(formatMessage);
    }
    return null;
  };

  const dateText = formatMessage(scheduledDate ? 'widgets.payCard.scheduledDateText' : 'widgets.payCard.dueDateText', {
    date: formatDate(scheduledDate ? scheduledDate : dueDate, { dateStyle: 'medium' }),
  });

  const recurringText = getPaymentCardRecurringText(isRecurringPaymentsEnabled, formatMessage, subscriptionOccurrence);

  // TODO: amount <-> balance
  return (
    <GroupItemCard
      itemType={GroupItemType.FAILED}
      topTag={getTagByType()}
      vendorName={vendorName}
      amount={payment.amount}
      dateText={dateText}
      recurringText={recurringText}
      bills={payment.bills}
      highlightedText={highlightedText}
      isChecked={itemSelections.includes(payment.id)}
      onCheck={handleItemChecked}
    />
  );
};

FailedPaymentPayCard.displayName = 'FailedPaymentPayCard';
