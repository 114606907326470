import { useMemo } from 'react';
import { useFundingSourcePermissions } from '@melio/ap-domain';
import { getFundingSourceBalance } from '@melio/ap-widgets';
import { ActionsDropdownMenuItemProps, NakedButton } from '@melio/penny';
import {
  BankAccount,
  Card,
  Card as CardAPI,
  FundingSource,
  FundingSource as FundingSourceAPI,
  FundingSourceType,
} from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { getSubjectData, usePermissions } from '@melio/platform-permissions';

import { usePlatformIntl } from '@/translations/Intl';

type AccountNameAndDigits = { accountName: string; accountNumber: string };
type FundingSourceNameAndDigits = (fundingSource: FundingSource) => AccountNameAndDigits;

export const extractNameAndAccountDigits: FundingSourceNameAndDigits = (fundingSource: FundingSource) => {
  const { displayName = '', type } = fundingSource;

  if (type === 'bank-account') {
    const accountNumber = (fundingSource.details as BankAccount).accountNumber.slice(-4) || '';
    const accountName = 'Bank account';

    return { accountName, accountNumber };
  } else if (type === 'card') {
    const { network = '', lastFourDigits } = fundingSource.details as Card;

    return { accountName: network, accountNumber: lastFourDigits };
  }

  return { accountName: displayName, accountNumber: '' };
};

export const useGetFundingSourceCardHelperText = ({
  fundingSource,
  fee,
  onClick,
  showAddedBy = false,
  isVerifying,
}: {
  fundingSource?: FundingSourceAPI;
  fee?: string | JSX.Element;
  onClick?: VoidFunction;
  showAddedBy?: boolean;
  isVerifying?: boolean;
}) => {
  const { formatMessage, formatCurrency, formatDate } = usePlatformIntl();
  const [isVirtualCardSupported] = useDevFeature(FeatureFlags.IsVirtualCardSupported, false);
  const { canUpdate } = useFundingSourcePermissions({ fundingSource });

  if (!fundingSource) {
    return '';
  }

  const fullName = [fundingSource.createdBy?.firstName, fundingSource.createdBy?.lastName].join(' ').trim();
  const addedByTitle = showAddedBy
    ? formatMessage('widgets.paymentMethods.paymentMethodLineItem.addedBy', {
        creator: fullName,
      })
    : undefined;

  const { type, isVerified, isBlocked } = fundingSource;

  if (type === 'bank-account') {
    const balance = getFundingSourceBalance(fundingSource);
    if (balance) {
      return formatMessage('activities.paymentFlow.form.content.fundingSourceCard.bank-account.line2', {
        availableBalance: formatCurrency(balance.availableBalance),
        availableBalanceUpdatedAt: formatDate(balance.availableBalanceUpdatedAt!, {
          month: 'short',
          day: 'numeric',
          hour: 'numeric',
          minute: '2-digit',
          timeZone: 'America/New_York',
          timeZoneName: 'short',
        }),
      });
    }

    if (isVerified) {
      return fee
        ? formatMessage('widgets.paymentMethods.paymentMethodLineItem.bankAccount.verified.helperText', {
            fee,
            addedBy: addedByTitle,
            accountType: fundingSource.details.accountType,
          })
        : addedByTitle;
    }

    if (isBlocked) {
      return formatMessage('widgets.paymentMethods.paymentMethodLineItem.bankAccount.blocked.helperText', {
        addedBy: addedByTitle,
      });
    }

    return formatMessage('widgets.paymentMethods.paymentMethodLineItem.bankAccount.unverified.helperText', {
      link: canUpdate && (
        <NakedButton
          variant="secondary"
          onClick={onClick}
          label={formatMessage('widgets.paymentMethods.paymentMethodLineItem.bankAccount.unverified.link')}
          data-testid="verify-funding-source-btn"
          isDisabled={isVerifying}
        />
      ),
      addedBy: addedByTitle,
    });
  } else if (type === 'flex-account') {
    return formatMessage('widgets.paymentMethods.paymentMethodLineItem.flexAccount.helperText');
  } else if ((fundingSource.details as Card)?.type == 'credit') {
    const key = !isVirtualCardSupported
      ? 'widgets.paymentMethods.paymentMethodLineItem.credit.helperText.withVirtualCard'
      : 'widgets.paymentMethods.paymentMethodLineItem.credit.helperText';
    return formatMessage(key, { fee, addedBy: addedByTitle });
  } else if ((fundingSource.details as Card)?.type == 'debit') {
    return formatMessage('widgets.paymentMethods.paymentMethodLineItem.debit.helperText', {
      fee,
      addedBy: addedByTitle,
    });
  }

  return `Missing Description for fundingType: ${fundingSource.type}, cardType: ${
    (fundingSource.details as Card)?.type
  }`;
};

export const useGetFundingSourceLabel = (fundingSource?: FundingSourceAPI) => {
  const { formatMessage } = usePlatformIntl();

  if (!fundingSource) {
    return '';
  }
  switch (fundingSource.type) {
    case FundingSourceType.BankAccount:
      return formatMessage('utils.paymentSource.bankAccount.label');
    case FundingSourceType.FlexAccount:
      return formatMessage('utils.paymentSource.flexAccount.label');
    case FundingSourceType.Card:
      return formatMessage(`utils.paymentSource.${(fundingSource.details as Card).type}.label`);
  }
};

export const classifyFundingSources = (fundingSources: FundingSourceAPI[]) => {
  const bankAccounts = fundingSources.filter((fs) => fs.type === 'bank-account');
  const debitCards = fundingSources.filter((fs) => fs.type === 'card' && (fs.details as Card).type === 'debit');
  const creditCards = fundingSources.filter((fs) => fs.type === 'card' && (fs.details as Card).type === 'credit');

  return {
    bankAccounts,
    debitCards,
    creditCards,
  };
};

type UseFundingSourceActions = {
  fundingSource: FundingSourceAPI;
  onDelete: VoidFunction;
  onEditLabel: VoidFunction;
  onSetAsDefault: VoidFunction;
  isReconciliationEnabledForFundingSource: boolean;
  dataTestIdPrefix: string;
  onEditSyncDetails: VoidFunction;
  onAssignUsersToFundingSource: VoidFunction;
  entitledFundingSourceCard?: boolean;
  onViewBankAccount: VoidFunction;
};

export const useFundingSourceActions = ({
  fundingSource,
  onDelete,
  onEditLabel,
  isReconciliationEnabledForFundingSource,
  dataTestIdPrefix,
  entitledFundingSourceCard,
  onAssignUsersToFundingSource,
  onEditSyncDetails,
  onSetAsDefault,
  onViewBankAccount,
}: UseFundingSourceActions) => {
  const { formatMessage } = usePlatformIntl();
  const { can } = usePermissions();
  const [setAsDefaultPaymentMethodEnabled] = useDevFeature(FeatureFlags.SetAsDefaultPaymentMethodEnabled, false);

  const { type, nickname } = fundingSource;
  const isExpired = isFundingSourceExpiredCard(fundingSource);
  const isBlocked = isFundingSourceBlocked(fundingSource);
  const viewAction = {
    label: formatMessage('widgets.paymentMethods.accountActions.viewBankDetails.label'),
    onClick: () => onViewBankAccount(),
    dataTestId: `${dataTestIdPrefix}-view`,
  };
  const addLabelAction = {
    label: formatMessage('widgets.paymentMethods.accountActions.addNickname.label'),
    onClick: onEditLabel,
    dataTestId: `${dataTestIdPrefix}-add-label`,
  };
  const editLabelAction = {
    label: formatMessage('widgets.paymentMethods.accountActions.editNickname.label'),
    onClick: onEditLabel,
    dataTestId: `${dataTestIdPrefix}-edit-label`,
  };
  const deleteAction = {
    label: formatMessage('widgets.paymentMethods.accountActions.delete.label'),
    onClick: onDelete,
    variant: 'critical' as const,
    dataTestId: `${dataTestIdPrefix}-delete`,
  };
  const setAsDefaultAction = {
    label: formatMessage('widgets.paymentMethods.accountActions.setAsDefault.label'),
    onClick: onSetAsDefault,
    dataTestId: `${dataTestIdPrefix}-set-as-default`,
  };
  const editSyncDetails = {
    label: formatMessage('widgets.paymentMethods.accountActions.editSyncDetails.label'),
    onClick: onEditSyncDetails,
    dataTestId: `${dataTestIdPrefix}-edit-sync-details`,
  };
  const assignUsersToFundingSource = {
    label: formatMessage('widgets.paymentMethods.accountActions.AssignUsers.label'),
    onClick: onAssignUsersToFundingSource,
    dataTestId: `${dataTestIdPrefix}-assign-users`,
  };

  let actions: ActionsDropdownMenuItemProps[] = [];

  const subjectData = getSubjectData({
    createdById: fundingSource.createdById,
    fundingType: fundingSource.type,
  });

  const canSetAsDefault = useMemo(() => {
    return can({
      subject: 'organizationPreference',
      action: 'create',
      subjectData: { orgPreferenceKey: 'defaultFundingSourceId' },
    });
  }, [can]);

  const canUpdateEntitlements = useMemo(() => {
    return can({
      subject: 'collaborator:entitlements:fundingSource',
      action: 'update',
    });
  }, [can]);

  if (entitledFundingSourceCard && canUpdateEntitlements) {
    actions.push(assignUsersToFundingSource);
  }

  if (type === FundingSourceType.BankAccount) {
    actions.push(viewAction);
  }
  if (
    isReconciliationEnabledForFundingSource &&
    can({ subject: 'fundingSource:AccountingPlatformPaymentAccount', action: 'update' })
  ) {
    actions.push(editSyncDetails);
  }
  if (
    ((type === 'bank-account' && !fundingSource.isBlocked) || type === 'card' || type === 'flex-account') &&
    can({ subject: 'fundingSource', action: 'update', subjectData })
  ) {
    actions.push(nickname ? editLabelAction : addLabelAction);
    if (setAsDefaultPaymentMethodEnabled && !fundingSource.isDefault && !isExpired && !isBlocked && canSetAsDefault) {
      actions.push(setAsDefaultAction);
    }
  }
  if (can({ subject: 'fundingSource', action: 'delete', subjectData })) {
    actions.push(deleteAction);
  }

  return actions;
};

export const isFundingSourceExpiredCard = (fs: FundingSourceAPI) => {
  if (fs.type === FundingSourceType.Card && fs.details) {
    const { expirationYear, expirationMonth } = fs.details as CardAPI;
    const expirationDate = new Date(`${expirationYear}-${expirationMonth}`);
    expirationDate.setMonth(expirationDate.getMonth() + 1);
    return expirationDate.getTime() < new Date().getTime();
  }
  return false;
};
export const isFundingSourceBlocked = (fs: FundingSourceAPI) => {
  if (fs.type === FundingSourceType.BankAccount && fs.details) {
    return fs.isBlocked;
  }
  return false;
};
