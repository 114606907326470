import { Form, Group, Modal, useFormSubmissionController, useMelioForm } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useVendorGroups } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useSystemMessage } from '@melio/platform-utils';
import { useCallback } from 'react';

import { useVendorsGroupFormSchema } from '../../../vendor-groups/vendors-group-form/schema';

type VendorGroupCreateNewGroupModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const VendorGroupCreateNewGroupModal = ({ isOpen, onClose }: VendorGroupCreateNewGroupModalProps) => {
  const { formatMessage } = useMelioIntl();
  const { track } = useAnalytics();
  const { submitButtonProps, onSubmissionStateChange } = useFormSubmissionController<{ name: string }>();

  const onModalClose = useCallback(() => {
    track('createNewVendorGroup', 'Chose', {});
    onClose();
  }, [onClose, track]);
  const { showMessage } = useSystemMessage();
  const { create, isMutating: isSaving } = useVendorGroups();
  const { data: vendorGroups, isLoading: isVendorGroupsLoading } = useVendorGroups();

  const handleCreateVendorGroup = async (data: { name: string }) => {
    try {
      await create({ name: data.name, vendorIds: [] });
      showMessage({
        title: formatMessage('activities.createVendorsGroupModal.toasts.success', { name: data.name }),
        type: 'success',
        dataTestId: 'create-vendors-group-success',
      });
      onClose();
    } catch (e) {
      showMessage({
        title: formatMessage('activities.createVendorsGroupModal.toasts.error', { name: data.name }),
        type: 'error',
        dataTestId: 'create-vendors-group-error',
      });
    }
  };

  const schema = useVendorsGroupFormSchema(vendorGroups?.map(({ name }) => name) ?? []);

  const { formProps, registerField } = useMelioForm<{ name: string }>({
    onSubmit: handleCreateVendorGroup,
    onSubmissionStateChange,
    schema,
    isSaving,
  });

  return (
    <Modal
      data-testid="vendor-group-create-new-group-modal"
      isLoading={isVendorGroupsLoading}
      isOpen={isOpen}
      onClose={onModalClose}
      header={formatMessage('activities.addVendor.screens.addVendorDetails.vendorGroup.addNewGroup.modal.title')}
      primaryButton={{
        label: formatMessage('activities.addVendor.screens.addVendorDetails.vendorGroup.addNewGroup.primaryButton'),
        variant: 'primary',
        ...submitButtonProps,
      }}
      secondaryButton={{
        label: formatMessage('activities.addVendor.screens.addVendorDetails.vendorGroup.addNewGroup.secondaryButton'),
        onClick: onModalClose,
        variant: 'secondary',
      }}
    >
      <Group variant="vertical">
        <Form {...formProps}>
          <Form.TextField
            {...registerField('name')}
            labelProps={{ label: formatMessage('activities.vendorsGroupForm.fields.name.label') }}
            isRequired
          />
        </Form>
      </Group>
    </Modal>
  );
};
