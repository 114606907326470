import { Box } from '@chakra-ui/react';
import { Badge, BrandSymbol } from '@melio/penny';
import { AccountingPlatformSlug } from '@melio/platform-api';
import { PropsWithChildren } from 'react';

export const AccountingPlatformIconWrapper = ({
  accountingPlatformSlug,
  children,
  enabled,
}: PropsWithChildren<{ accountingPlatformSlug: AccountingPlatformSlug; enabled: boolean }>) => {
  if (!enabled) {
    return <>{children}</>;
  }

  return (
    <Badge
      customPositions={{
        bottom: '-0.1rem',
        right: '-0.1rem',
      }}
      mark={
        <Box
          alignItems="center"
          backgroundColor="semantic.background.primary"
          borderRadius="global.full"
          display="flex"
          height="20px"
          justifyContent="center"
          width="20px"
          marginBottom="-0.2rem"
          marginRight="-0.2rem"
          data-testid="sync-now-platform-icon"
          data-tourid="sync-now-platform-icon"
        >
          <BrandSymbol size="small" type={accountingSoftwareSlugToBrandSymbolType[accountingPlatformSlug]} />
        </Box>
      }
    >
      {children}
    </Badge>
  );
};

export const accountingSoftwareSlugToBrandSymbolType: Record<
  AccountingPlatformSlug,
  Parameters<typeof BrandSymbol>[0]['type']
> = {
  ['xero']: 'xero-logo',
  ['quickBooksOnline']: 'quickbooks',
  ['quickBooksDesktop']: 'quickbooks',
  ['quickBooksDesktopInApp']: 'quickbooks',
  ['dynamicsBusinessCentral']: 'dynamics365bc',
  ['freshBooks']: 'freshbooks-logo',
};
