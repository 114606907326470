import { Form, UseMelioFormResults } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';

import { PaymentFlowFormFields, PaymentFlowOnChangeHandlers } from '../../../types';
import { RadioLabelWithTooltip } from './util/RadioLabelWithTooltip';

export function FrequencySelector({
  form,
  isDisabled,
  disabledText,
  onChange,
}: {
  form: UseMelioFormResults<PaymentFlowFormFields>;
  isDisabled: boolean;
  disabledText: {
    oneTime?: string;
    recurring?: string;
  };
  onChange: PaymentFlowOnChangeHandlers['onRecurrenceTypeChange'];
}) {
  const { track } = useAnalytics();
  const { formatMessage } = useMelioIntl();
  const { registerField } = form;

  return (
    <Form.RadioGroup
      {...registerField('recurrenceType')}
      labelProps={{ label: formatMessage('activities.paymentFlow.form.content.recurring.recurrenceType.label') }}
      variant="horizontal"
      options={[
        {
          value: 'one_time',
          label: (
            <RadioLabelWithTooltip
              label={formatMessage('activities.paymentFlow.form.content.recurring.recurrenceType.options.one_time')}
              tooltip={disabledText.oneTime}
              isDisabled={isDisabled || !!disabledText.oneTime}
            />
          ),
          disabled: disabledText.oneTime ? { isDisabled: true } : undefined,
          ariaLabel: formatMessage(
            'activities.paymentFlow.form.content.recurring.recurrenceType.options.one_time.ariaLabel'
          ),
        },
        {
          value: 'recurring',
          label: (
            <RadioLabelWithTooltip
              label={formatMessage('activities.paymentFlow.form.content.recurring.recurrenceType.options.recurring')}
              tooltip={disabledText.recurring}
              isDisabled={isDisabled || !!disabledText.recurring}
            />
          ),
          disabled: disabledText?.recurring ? { isDisabled: true } : undefined,
          ariaLabel: formatMessage(
            'activities.paymentFlow.form.content.recurring.recurrenceType.options.recurring.ariaLabel'
          ),
        },
      ]}
      isDisabled={isDisabled}
      onChange={(e) => {
        const recurrenceType = e.target.value as PaymentFlowFormFields['recurrenceType'];
        track('Payment', 'Click', { Intent: 'set-payment-frequency', Cta: recurrenceType });
        onChange(recurrenceType);
      }}
    />
  );
}
