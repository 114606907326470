import { usePaymentFee } from '@melio/ap-domain';
import { Group, Icon, Text, Tooltip } from '@melio/penny';
import { FeesBreakdown } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

export const FeesBreakdownByType = ({ feesBreakdown }: { feesBreakdown: FeesBreakdown | undefined }) => {
  const { formatMessage, formatCurrency } = useMelioIntl();
  const fees = usePaymentFee(feesBreakdown?.feesBreakdown ?? []);

  if (!feesBreakdown || feesBreakdown.feesToPay === 0) {
    return null;
  }

  return (
    <Group variant="vertical" spacing="xs" alignItems="flex-end">
      {fees?.map((fee, index) => {
        switch (fee.type) {
          case 'debit':
          case 'credit': {
            return (
              <Group key={index} justifyContent="space-between" textAlign="end">
                <Text textStyle="body4" data-testid="batch-payments-amount-header-fee-breakdown-card-fee-description">
                  {fee.description}
                </Text>
                <Text data-testid="batch-payments-amount-header-fee-breakdown-card-fee" textStyle="body4">
                  {formatCurrency(fee.amount)}
                </Text>
              </Group>
            );
          }
          default:
            return null;
        }
      })}
      {feesBreakdown.feesToPay > 0 && (
        <Group alignItems="center" textAlign="end">
          <Group alignItems="center" spacing="xxs" textAlign="end">
            <Text textStyle="body4Semi" color="global.neutral.900">
              {formatMessage(
                'activities.batchPayments.screens.paymentIntentsTable.amountHeader.feesBreakdown.total.label'
              )}
            </Text>
            <Tooltip
              content={formatMessage(
                'activities.batchPayments.screens.paymentIntentsTable.amountHeader.feesBreakdown.total.tooltip'
              )}
              data-testid="batch-payments-amount-header-fee-breakdown-total-tooltip"
            >
              <Icon
                type="info"
                size="small"
                data-testid="batch-payments-amount-header-fee-breakdown-total-tooltip-trigger"
              />
            </Tooltip>
          </Group>
          <Text data-testid="batch-payments-amount-header-fee-breakdown-total-amount" textStyle="body4Semi">
            {formatCurrency(feesBreakdown.feesToPay)}
          </Text>
        </Group>
      )}
    </Group>
  );
};
