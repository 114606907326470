import { useCustomer, useInvoice, useMelioIntl, useSystemMessage } from '@melio/ar-domain';
import { useAnalytics } from '@melio/platform-analytics';
import { useBoolean } from '@melio/platform-utils';
import { kebabCase } from 'lodash';
import { useState } from 'react';

import { OnBeforeDone, ShareMode } from '../../../types';
import { IssueInvoiceFormFields, SubmitTarget } from '../types';
import { useScreenLabels } from './useScreenLabels';
import { useSuccessMessage } from './useSuccessMessage';

type Props = {
  invoiceId: string;
  shouldSend: boolean;
  onError?: ARErrorFunction;
  onDone?: (id: string) => unknown;
  onBeforeDone?: OnBeforeDone;
  shareMode: ShareMode;
  onClose?: VoidFunction;
  onEditInvoice?: (id: string) => unknown;
};
export const useSendInvoiceActivity = ({ invoiceId, onBeforeDone, shareMode, shouldSend, ...props }: Props) => {
  const [submitTarget, setSubmitTarget] = useState<SubmitTarget>();
  const getSuccessMessage = useSuccessMessage();
  const { formatMessage } = useMelioIntl();
  const { track } = useAnalytics();
  const { primaryButtonLabel, secondaryButtonLabel } = useScreenLabels({ shareMode, shouldSend });
  const { triggerMessage } = useSystemMessage();

  const onError = (error: ARPlatformError) => {
    props.onError?.(error);
    triggerMessage({
      type: 'critical',
      title: formatMessage(`ar.invoiceLifecycle.activities.issueInvoice.${shareMode}.systemMessage.error.label`),
    });
  };

  const { shareInvoice, invoice, customer, isLoading, isSaving, paymentRequestLink, paymentRequestId } =
    useInvoiceActions({
      invoiceId,
      onError,
    });

  const [isOnBeforeDone, onBeforeDoneState] = useBoolean(false);
  const submitHandler = async (formData: IssueInvoiceFormFields, target?: SubmitTarget) => {
    setSubmitTarget(target);

    try {
      switch (target) {
        case 'primary':
          await handleSubmitTargetPrimary(formData, target);
          break;
        case 'secondary':
          track('Invoice', 'Click', {
            Cta: kebabCase(secondaryButtonLabel),
            Intent: 'exit-flow',
          });
          props.onEditInvoice?.(invoiceId);
          break;
        case 'tertiary':
          props.onClose?.();
          break;
      }
    } catch (error) {
      track('Invoice', 'Status', {
        StatusType: 'failure',
        ErrorType: (error as PlatformError).message,
        Cta: kebabCase(primaryButtonLabel),
      });
      onError(error as ARPlatformError);
    } finally {
      setSubmitTarget(undefined);
    }
  };

  const copyPaymentLink = async () => {
    if (!paymentRequestLink) throw new Error('Payment link is not available');
    await navigator.clipboard.writeText(paymentRequestLink);
  };

  const handleSubmitTargetPrimary = async (formData: IssueInvoiceFormFields, target?: SubmitTarget) => {
    track('Invoice', 'Click', {
      Cta: kebabCase(primaryButtonLabel),
      Intent: shareMode === 'reminder' ? 'send-reminder' : 'send-invoice-to-customer',
    });

    const trackSendSuccess = () => {
      track('Invoice', 'Status', {
        StatusType: 'success',
        Cta: kebabCase(primaryButtonLabel),
        PaymentRequestId: paymentRequestId,
      });
    };
    const handleShareInvoicePromise = async () => {
      await handleShareInvoice(formData, target);
      trackSendSuccess();
    };

    if (onBeforeDone) {
      onBeforeDoneState.on();
      await onBeforeDone(handleShareInvoicePromise, onBeforeDoneState.off);
    } else {
      await handleShareInvoicePromise();
    }
  };

  const handleShareInvoice = async (
    { shouldSendEmail, emailMessage }: IssueInvoiceFormFields,
    target?: SubmitTarget
  ) => {
    if (shouldSendEmail === 'yes') {
      await shareInvoice({ sentFrom: 'partner', type: shareMode, emailMessage });
    }

    props.onDone?.(invoiceId);
    triggerMessage({
      type: 'success',
      title: getSuccessMessage({ target, shareMode, shouldSendEmail, invoiceNumber: invoice?.invoiceNumber }),
    });
  };

  const handleCopyPaymentLink = async () => {
    try {
      await copyPaymentLink();
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const handleBackClick = () => {
    if (shareMode === 'createInvoice' || shareMode === 'updateInvoice') {
      props.onEditInvoice?.(invoiceId);
    } else {
      props.onClose?.();
    }
  };

  return {
    customer,
    isLoading,
    isSaving: isOnBeforeDone || isSaving,
    submitHandler,
    submitTarget,
    handleCopyPaymentLink,
    paymentRequestLink,
    isDraft: !invoice?.issuedAt,
    invoice,
    handleBackClick,
  };
};

export const useInvoiceActions = ({ invoiceId, onError }: Pick<Props, 'invoiceId' | 'onError'>) => {
  const { data: invoice, ...invoiceModel } = useInvoice({ id: invoiceId, onError });
  const { data: customer, ...customerModel } = useCustomer({ id: invoice?.customerId, onError });

  return {
    invoice,
    customer,
    isLoading: invoiceModel.isLoading || customerModel.isLoading,
    isSaving: invoiceModel.isMutating || customerModel.isMutating,
    paymentRequestLink: invoice?.paymentRequest?.link,
    paymentRequestId: invoice?.paymentRequest?.id,
    shareInvoice: invoiceModel.share,
  };
};
