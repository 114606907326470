import { useAccountingPlatformName } from '@melio/ap-widgets/src/components/funding-sources';
import { accountingSoftwareSlugToBrandSymbolType } from '@melio/ap-widgets/src/components/SyncNow';
import { BrandSymbol, Link, SectionBanner } from '@melio/penny';
import { EventProperties, useAnalytics } from '@melio/platform-analytics';
import {
  getAvailableToConnectAccountingPlatforms,
  useAccountingPlatforms,
  useUserHasPayments,
  useUserPreference,
} from '@melio/platform-api';
import { useMelioIntl, usePartnerFeature } from '@melio/platform-provider';
import { useState } from 'react';

import { useActivitiesNavigate } from '../../utils';

const SYNC_PROMOTION_PREFERENCE_ID = 'dismissedAccountingPlatformSyncPromotionBanner';
const analyticsUserMessage = 'connect-your-quickbooks-online-account';

export const useShouldPromoteAccountingSoftwareSync = () => {
  const [isEnabled] = usePartnerFeature('PromoteAccountingSoftwareSync', false);

  return isEnabled;
};

export const useFirstAccountingPlatformSlug = () => {
  const { data, hasAccountingPlatform } = useAccountingPlatforms();
  const accountingSlug = getAvailableToConnectAccountingPlatforms(data)?.[0]?.accountingSlug;
  const accountingPlatformName = useAccountingPlatformName(accountingSlug);

  return {
    accountingSlug,
    accountingPlatformName,
    hasAccountingPlatform,
  };
};

export type PromoteAccountingSoftwareSyncBannerProps = {
  dismissScope: (typeof DISMISS_SCOPE)[keyof typeof DISMISS_SCOPE] | null;
  'data-testid'?: string;
  analyticsProps: PromoteAccountingSoftwareSyncAnalyticsProperties;
};

type PromoteAccountingSoftwareSyncAnalyticsProperties = {
  EntryPoint?: string;
  Flow?: string;
  PageName: string;
} & EventProperties;

const DISMISS_SCOPE = {
  ALL: 'all',
  VENDOR: 'vendor',
} as const;

export const useShowPromoteAccountingSoftwareSyncBanner = ({
  dismissScope,
}: {
  dismissScope: (typeof DISMISS_SCOPE)[keyof typeof DISMISS_SCOPE] | null;
}) => {
  const isFeatureEnabled = useShouldPromoteAccountingSoftwareSync();
  const { data: { value: preference } = {} } = useUserPreference({
    id: SYNC_PROMOTION_PREFERENCE_ID,
    enabled: isFeatureEnabled,
  });
  const wasBannerDismissed = preference && (preference === dismissScope || preference === DISMISS_SCOPE.ALL);

  const { userHasPayments } = useUserHasPayments();
  const isEmptyState = !userHasPayments;

  const showBanner = !isEmptyState && isFeatureEnabled && !wasBannerDismissed;

  return {
    showBanner,
    viewAnalyticsProps: showBanner
      ? {
          UserMessage: analyticsUserMessage,
        }
      : {},
  };
};

export const PromoteAccountingSoftwareSyncBanner = ({
  dismissScope,
  'data-testid': testId,
  analyticsProps,
}: PromoteAccountingSoftwareSyncBannerProps) => {
  const { showBanner } = useShowPromoteAccountingSoftwareSyncBanner({ dismissScope });

  if (!showBanner) {
    return null;
  }

  return (
    <PromoteAccountingSoftwareSyncBannerInternal
      dismissScope={dismissScope}
      data-testid={testId}
      analyticsProps={analyticsProps}
    />
  );
};

export const PromoteAccountingSoftwareSyncBannerInternal = ({
  dismissScope,
  'data-testid': testId,
  analyticsProps,
}: PromoteAccountingSoftwareSyncBannerProps) => {
  const { formatMessage } = useMelioIntl();
  const { accountingSlug, accountingPlatformName, hasAccountingPlatform } = useFirstAccountingPlatformSlug();
  const { track } = useAnalytics();
  const { navigateToSettingsAccountingPlatform } = useActivitiesNavigate();
  const { create } = useUserPreference({
    id: SYNC_PROMOTION_PREFERENCE_ID,
    enabled: true,
  });
  const [isSavingUserPreference, setIsSavingUserPreference] = useState(false);

  const dismissBanner = async () => {
    track('SyncAccountingSoftware', 'Click', {
      Cta: 'disable-user-message',
      UserMessage: analyticsUserMessage,
      Intent: 'disable-accounting-software-sync-massage',
      ...analyticsProps,
    });
    setIsSavingUserPreference(true);
    await create({ userPreference: { key: SYNC_PROMOTION_PREFERENCE_ID, value: dismissScope } });
  };

  const handleConnectAndSync = () => {
    track('SyncAccountingSoftware', 'Click', {
      Cta: 'connect-and-sync',
      UserMessage: analyticsUserMessage,
      Intent: 'accounting-software-sync',
      ...analyticsProps,
    });
    navigateToSettingsAccountingPlatform();
  };

  if (isSavingUserPreference || hasAccountingPlatform || !accountingSlug) {
    return null;
  }

  const brandSymbolType = accountingSlug && accountingSoftwareSlugToBrandSymbolType[accountingSlug];

  return (
    <SectionBanner
      data-component="PromoteAccountingSoftwareSyncBanner"
      data-testid={testId ?? 'promote-accounting-software-sync-banner'}
      variant="secondary"
      title={formatMessage('widgets.promoteAccountingSoftwareSyncBanner.title', {
        accountingPlatformName,
      })}
      description={formatMessage('widgets.promoteAccountingSoftwareSyncBanner.description', {
        accountingPlatformName,
      })}
      leftElement={brandSymbolType && <BrandSymbol type={brandSymbolType} />}
      onClose={dismissBanner}
      showCloseIcon={dismissScope !== null}
      action={
        <Link
          href="#"
          onClick={handleConnectAndSync}
          label={formatMessage('widgets.promoteAccountingSoftwareSyncBanner.cta')}
          data-testid="promote-accounting-software-sync-banner-cta"
        />
      }
    />
  );
};

PromoteAccountingSoftwareSyncBanner.displayName = 'PromoteAccountingSoftwareSyncBanner';
