import { SearchBar } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { usePartnerFeature } from '@melio/platform-provider';
import { debounce } from 'lodash';
import { ChangeEvent, KeyboardEvent, useMemo, useState } from 'react';

const DEBOUNCE_DELAY = 500;

type Props = {
  onSearchSubmitted: (searchTerm: string) => void;
};

export const VendorsGroupSearchBar = ({ onSearchSubmitted }: Props) => {
  const { formatMessage } = useMelioIntl();
  const [localSearchString, setLocalSearchString] = useState<string>('');
  const [isSearchOnKeyPressEnabled] = usePartnerFeature('PayDashboardSearchOnKeyPress', false);

  const debouncedOnSearchSubmitted = useMemo(
    () =>
      debounce((val: string) => {
        onSearchSubmitted(val);
      }, DEBOUNCE_DELAY),
    [onSearchSubmitted]
  );

  const handleOnKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSearchSubmitted(localSearchString);
    }
  };

  const handleOnValueClear = () => {
    setLocalSearchString('');
    onSearchSubmitted('');
  };

  const handleOnValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    setLocalSearchString(val);
    if (isSearchOnKeyPressEnabled) {
      debouncedOnSearchSubmitted(val);
    }
  };

  return (
    <SearchBar
      data-testid="vendors-group-form-search-input"
      value={localSearchString}
      onClear={handleOnValueClear}
      onKeyDown={handleOnKeyDown}
      onChange={handleOnValueChange}
      placeholder={formatMessage('activities.vendorsGroupForm.search.placeholder')}
      onSearch={() => onSearchSubmitted(localSearchString)}
      isFullWidth
    />
  );
};
