import { useInternationalFxFeature, useIsLineItemsAvailable } from '@melio/ap-domain';
import { AccountingPlatform, AccountingPlatformSlug } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { useDateUtils } from '@melio/platform-utils';
import { useCallback, useMemo } from 'react';

import {
  AddBillV2DFormInitialValues,
  AddBillV2FormFrequency,
  AddBillV2FormValues,
  AddBillV2RecurringEndBy,
  BaseLineItem,
  BillLineItemType,
  CategoryBasedBillLineItem,
  ItemBasedBillLineItem,
  XeroSyncedBillLineItem,
} from '../types';
import { createEmptyCategoryLineItem, createEmptyUnsyncedLineItem, createEmptyXeroSyncedLineItem } from '../utils';

type UseDefaultFormValuesResult = {
  defaultFormValues: AddBillV2FormValues;
  getDefaultFormValues: (initialValues?: AddBillV2DFormInitialValues) => AddBillV2FormValues;
};

export const useDefaultFormValues = ({
  values,
  hasAccountingPlatform,
  activeAccountingPlatform,
}: {
  values?: AddBillV2DFormInitialValues;
  hasAccountingPlatform: boolean;
  activeAccountingPlatform?: AccountingPlatform;
}): UseDefaultFormValuesResult => {
  const { formatMessage } = useMelioIntl();
  const {
    settings: {
      newBillExperience: { isRecurringPaymentImprovementsEnabled },
    },
  } = useConfig();

  const { createDate } = useDateUtils();
  const today = createDate();
  const isLineItemsAvailable = useIsLineItemsAvailable();
  const isInternationalFxEnabled = useInternationalFxFeature();

  const getDefaultLineItemsValues = useCallback(
    (initialValues?: AddBillV2DFormInitialValues) => {
      const emptyLineItems: {
        nonSyncedLineItems: BaseLineItem[];
        categoryBasedLineItems: CategoryBasedBillLineItem[];
        xeroSyncedLineItems: XeroSyncedBillLineItem[];
        itemBasedLineItems: ItemBasedBillLineItem[];
      } = {
        nonSyncedLineItems: [],
        categoryBasedLineItems: [],
        xeroSyncedLineItems: [],
        itemBasedLineItems: [],
      };

      if (!isLineItemsAvailable) {
        return emptyLineItems;
      }

      if (!hasAccountingPlatform) {
        const nonSyncedLineItems = initialValues?.lineItems?.length
          ? (initialValues.lineItems.map((lineItem) => ({
              ...lineItem,
              amount: lineItem.amount ?? '0.00',
            })) as BaseLineItem[])
          : [createEmptyUnsyncedLineItem({ amount: initialValues?.amount || '' })];

        return {
          ...emptyLineItems,
          nonSyncedLineItems,
        };
      }

      const isXeroAccountingPlatform = activeAccountingPlatform?.accountingSlug === AccountingPlatformSlug.Xero;

      if (isXeroAccountingPlatform && initialValues?.lineItems) {
        const xeroSyncedLineItems = initialValues.lineItems.map((lineItem) => ({
          ...lineItem,
          amount: lineItem.amount ?? '0.00',
        })) as XeroSyncedBillLineItem[];

        return {
          ...emptyLineItems,
          xeroSyncedLineItems,
        };
      }

      if (isXeroAccountingPlatform) {
        return {
          ...emptyLineItems,
          xeroSyncedLineItems: [
            createEmptyXeroSyncedLineItem({
              amount: initialValues?.amount || '',
              externalCategoryId: initialValues?.categoryId || null,
            }),
          ],
        };
      }

      if (initialValues?.lineItems) {
        const categoryBasedLineItems = initialValues.lineItems
          .filter((lineItem): lineItem is CategoryBasedBillLineItem => lineItem.type === BillLineItemType.CATEGORY)
          .map((lineItem) => ({ ...lineItem, amount: lineItem.amount ?? '0.00' }));
        const itemBasedLineItems = initialValues.lineItems
          .filter((lineItem): lineItem is ItemBasedBillLineItem => lineItem.type === BillLineItemType.ITEM)
          .map((lineItem) => ({ ...lineItem, amount: lineItem.amount ?? '0.00' }));

        if (!categoryBasedLineItems.length && !itemBasedLineItems.length) {
          return {
            ...emptyLineItems,
            categoryBasedLineItems: [
              createEmptyCategoryLineItem({
                amount: initialValues?.amount || '',
                externalCategoryId: initialValues?.categoryId || null,
              }),
            ],
          };
        }

        return {
          ...emptyLineItems,
          categoryBasedLineItems,
          itemBasedLineItems,
        };
      }

      return {
        ...emptyLineItems,
        categoryBasedLineItems: [
          createEmptyCategoryLineItem({
            amount: initialValues?.amount || '',
            externalCategoryId: initialValues?.categoryId || null,
          }),
        ],
      };
    },
    [activeAccountingPlatform?.accountingSlug, hasAccountingPlatform, isLineItemsAvailable]
  );

  const getDefaultFormValues = useCallback(
    (initialValues?: AddBillV2DFormInitialValues) => {
      let noteToSelfValue = initialValues?.noteToSelf;
      if (!noteToSelfValue && hasAccountingPlatform && !initialValues?.id) {
        noteToSelfValue = formatMessage('activities.addBillV2.billForm.noteToSelf.default');
      }

      const defaultValues: AddBillV2FormValues = {
        amount: initialValues?.amount ?? '',
        dueDate: initialValues?.dueDate ?? today,
        vendorId: initialValues?.vendorId ?? '',
        categoryId: initialValues?.categoryId,
        noteToSelf: noteToSelfValue ?? '',
        invoiceNumber: initialValues?.invoiceNumber ?? '',
        invoiceDate: initialValues?.invoiceDate,
        frequency: AddBillV2FormFrequency.ONE_TIME,
        currency: isInternationalFxEnabled ? initialValues?.currency : undefined,
        recurringEndBy: isRecurringPaymentImprovementsEnabled
          ? AddBillV2RecurringEndBy.NO_END_DATE
          : AddBillV2RecurringEndBy.DATE,
        recurringOccurrences: '',
        externalLabelId: initialValues?.externalLabelId,
      };

      const defaultLineItemsValues = getDefaultLineItemsValues(initialValues);

      return {
        ...defaultValues,
        ...defaultLineItemsValues,
      };
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      hasAccountingPlatform,
      isRecurringPaymentImprovementsEnabled,
      getDefaultLineItemsValues,
      formatMessage,
      isInternationalFxEnabled,
    ]
  );

  return {
    defaultFormValues: useMemo(() => getDefaultFormValues(values), [values, getDefaultFormValues]),
    getDefaultFormValues,
  };
};
