import React, { RefObject } from 'react';
import { Box } from '@chakra-ui/react';
import { useSetRecoilState } from 'recoil';
import { Button, NakedButton } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { Bill, BillInfo, Payment } from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { usePermissions } from '@melio/platform-permissions';

import { payDashboardSelectedCardSelector } from '@/store/PayDashboard/PayDashboards.model';
import { usePlatformIntl } from '@/translations/Intl';
import { getPaymentActivityRecurringText } from '@/utils/billSubscription.utils';
import { PayDetailsTop } from '@/widgets/pay-dashboard/pay-details-screen-top/PayDetailsTop.component';

type PayDetailsScreenTopParams = {
  payment: Payment;
  retryCollect: VoidFunction;
  retryDeliver: VoidFunction;
  billDetailsRef: RefObject<HTMLDivElement>;
  bills?: (BillInfo | Bill)[];
};

export const PaymentDetailsScreenTop = ({
  payment,
  retryCollect,
  retryDeliver,
  billDetailsRef,
  bills,
}: PayDetailsScreenTopParams) => {
  const { formatMessage } = usePlatformIntl();
  const setSelectedPaymentCard = useSetRecoilState(payDashboardSelectedCardSelector);
  const { can } = usePermissions();
  const hasRecoverPermission = can({
    subject: 'payment',
    action: 'recover',
    subjectData: {
      createdById: payment.createdById,
      fundingSourceId: payment.fundingSourceId,
      vendor: {
        createdById: payment.vendor?.createdById,
        managedBy: payment.vendor?.managedBy,
      },
      payment: {
        origin: payment.origin,
      },
    },
  });
  const canRetryCollect = payment.paymentActions?.retryCollect.eligible && hasRecoverPermission;
  const canRetryDeliver = !canRetryCollect && payment.paymentActions?.retryDeliver.eligible && hasRecoverPermission;
  const canRetry = canRetryCollect || canRetryDeliver;
  const retryLabelKey = canRetryCollect
    ? 'widgets.payDetailsScreen.resolveAndReschedulePayment'
    : 'widgets.payDetailsScreen.retryPayment';
  const { track } = useAnalytics();
  const onRetryClicked = () => {
    track('Dashboard', 'Click', {
      PageName: canRetryCollect ? 'payment-failed-to-collect' : 'payment-failed-to-deliver',
      Intent: 'retry-failed-payment',
      FailureCode: payment.paymentFailure?.failureCode,
      FailedDestination: canRetryCollect ? 'collect-failure' : 'deliver-failure',
      Cta: retryLabelKey,
    });
    if (canRetryCollect) {
      return retryCollect();
    }
    return retryDeliver();
  };

  const [isRecurringPaymentsEnabled] = useDevFeature<boolean>(FeatureFlags.RecurringPayments, false);

  const scrollToBillDetails = React.useCallback(() => {
    billDetailsRef?.current?.scrollIntoView({ behavior: 'smooth' });
  }, [billDetailsRef]);

  const scrollToPaymentDetailsButton = (
    <NakedButton
      onClick={scrollToBillDetails}
      variant="secondary"
      label={formatMessage('widgets.payDetailsScreen.viewDetails')}
    />
  );

  let subTitleText = '';
  if (isRecurringPaymentsEnabled && payment.subscriptionOccurrence) {
    subTitleText = getPaymentActivityRecurringText(formatMessage, payment.subscriptionOccurrence);
  } else if (bills) {
    if (bills.length > 1) {
      subTitleText = formatMessage('widgets.payDetailsScreen.multipleBills', {
        numberOfBills: bills.length,
      });
    } else if (bills[0]?.invoice.number) {
      subTitleText = formatMessage('widgets.payDetailsScreen.invoiceNumber', {
        invoiceNumber: bills[0]?.invoice.number,
      });
    }
  }

  const vendorName = bills?.[0]?.vendor?.name || '';

  const subTitle = formatMessage('widgets.payDetailsScreen.subTitle', {
    subTitle: subTitleText,
    viewDetails: payment ? scrollToPaymentDetailsButton : '',
  });

  return (
    <PayDetailsTop vendorName={vendorName} subTitle={subTitle} onBackClick={() => setSelectedPaymentCard({})}>
      {canRetry ? (
        <Box>
          <Button
            onClick={onRetryClicked}
            variant={'primary'}
            data-testid={'resolve-and-reschedule-payment-button'}
            label={formatMessage(retryLabelKey)}
          />
        </Box>
      ) : null}
    </PayDetailsTop>
  );
};
