/* eslint-disable react-hooks/exhaustive-deps */
import {
  Container,
  Form,
  Group,
  Modal,
  StatusIconSolid,
  Text,
  useBreakpoint,
  useFormSubmissionController,
  useMelioForm,
} from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { isEmpty } from 'lodash';
import { ComponentProps, useCallback, useEffect } from 'react';

import { FormFields, useFormSchema } from './useFormSchema';

type ModeSpecificProps = { mode: 'edit'; value?: string } | { mode: 'confirm'; value: string };

type Props = Pick<ComponentProps<typeof Modal>, 'isOpen' | 'onClose'> & {
  isLoading: boolean;
  isManaged: boolean;
  vendorId?: string;
  onSubmit: () => void;
  setExternalFormValue?: (value: string) => void;
} & ModeSpecificProps;

export const VendorNameInQBOModal = ({
  isOpen,
  isLoading,
  mode,
  value,
  isManaged,
  vendorId,
  onSubmit,
  onClose,
  setExternalFormValue,
}: Props) => {
  const { formatMessage } = useMelioIntl();
  const { track, createTrackHandler } = useAnalytics();
  const { isExtraSmallScreen: isMobile } = useBreakpoint();
  const trackActionClick = createTrackHandler('Vendor', 'Click');
  const isEditMode = mode === 'edit';
  const defaultAnalyticsFields = {
    PageName: 'vendor-name-in-qbo',
    Flow: isEditMode ? 'edit-vendor' : 'add-vendor',
    Intent: 'add-vendor-details',
    CountInTab: 1,
    VendorType: isManaged ? 'managed' : 'unmanaged',
    VendorId: vendorId ? vendorId : undefined,
  };
  const { submitButtonProps, onSubmissionStateChange } = useFormSubmissionController<FormFields>();
  const { formProps, registerField, clearErrors, setValue } = useMelioForm<FormFields>({
    schema: useFormSchema({ vendorId, isManaged }),
    onSubmit: () => {
      track('Vendor', 'Status', {
        ...defaultAnalyticsFields,
        Status: 'success',
      });
      onSubmit();
    },
    onError: () => {
      track('Vendor', 'Status', {
        ...defaultAnalyticsFields,
        Status: 'failure',
        ErrorType: 'vendor-same-name-exists-in-qbo',
      });
    },
    onSubmissionStateChange,
    defaultValues: {
      uniqueName: isEditMode ? '' : value,
    },
  });
  const {
    settings: { partnerProductName },
  } = useConfig();

  useEffect(() => {
    if (isOpen) {
      track('Vendor', 'View', { ...defaultAnalyticsFields });

      if (!isEmpty(value) && value) {
        setExternalFormValue?.(value);
        setValue('uniqueName', value);
      }
    }
  }, [isOpen, track]);

  const handleOnClose = useCallback(
    (isExit: boolean) => {
      clearErrors('uniqueName');
      trackActionClick({ Cta: isExit ? 'exit' : 'cancel', ...defaultAnalyticsFields }, () => onClose());
    },
    [clearErrors, onClose]
  );

  return (
    <Modal
      data-testid="vendor-name-in-qbo-modal"
      isOpen={isOpen}
      onClose={() => handleOnClose(true)}
      header={
        <Container paddingTop="xxs">
          <Group spacing="s" alignItems="flex-start">
            {!isMobile && (
              <Group.Item grow={0}>
                <Container width="fit-content">
                  <StatusIconSolid variant="informative" size="large" />
                </Container>
              </Group.Item>
            )}
            <Text as="h2" textStyle="heading2Semi" data-testid="modal-header-text">
              {formatMessage('widgets.vendorNameInQBOModal.title')}
            </Text>
          </Group>
        </Container>
      }
      primaryButton={{
        ...submitButtonProps,
        label: formatMessage(`widgets.vendorNameInQBOModal.buttons.primary.${mode}`),
        variant: 'primary',
        isLoading,
      }}
      secondaryButton={{
        label: formatMessage('widgets.vendorNameInQBOModal.buttons.secondary'),
        onClick: () => handleOnClose(false),
        variant: 'secondary',
      }}
    >
      <Group variant="vertical" spacing="m">
        <Text>
          {formatMessage(`widgets.vendorNameInQBOModal.description.${mode}`, {
            productName: partnerProductName,
            companyName: value,
          })}
        </Text>
        <Form
          {...formProps}
          onSubmit={(e) => {
            e.stopPropagation();
            formProps.onSubmit(e);
          }}
          data-testid="vendor-name-in-qbo-form"
          columns={2}
        >
          <Form.TextField
            colSpan={2}
            {...registerField('uniqueName')}
            data-testid="form-input-uniqueName-qbo-modal"
            autoFocus={isEditMode}
            isDisabled={mode === 'confirm' || isLoading}
            isLoading={isLoading}
            onChange={(e) => {
              setExternalFormValue?.(e.target.value);
              setValue('uniqueName', e.target.value);
            }}
            isRequired
            labelProps={{
              label: formatMessage('widgets.vendorNameInQBOModal.form.companyNameInQBO.label'),
            }}
          />
        </Form>
      </Group>
    </Modal>
  );
};
