import { usePrevious } from '@chakra-ui/react';
import { useBreakpoint } from '@melio/penny';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useConfig, useMelioIntl } from '@melio/platform-provider';
import { useIsSubscriptionsEnabled, useSubscription } from '@melio/subscriptions';
import { useEffect } from 'react';

import { PlatformTourtip, usePlatformTourtip } from '../../../platform-tourtip';
import { PayDashboardTabs } from '../../types';
import { useVendorsTabData } from '../VendorsTab/useVendorsTabData';
import { PAY_DASHBOARD_TOUR_PREFERENCE_KEY } from './consts';
import { useDashboardTourSteps } from './useDashboardTourSteps';

export type Props = {
  activeTab: PayDashboardTabs;
  onIsFirstTimeExperienceTourtipRunningChange: (value: boolean) => void;
  isFirstTimeExperienceTourtipRunning: boolean;
};

const DASHBOARD_TOUR_SUPPORTED_TABS = [PayDashboardTabs.Vendors, PayDashboardTabs.Bills];

export const PayDashboardTour = ({ activeTab, onIsFirstTimeExperienceTourtipRunningChange }: Props) => {
  const subscription = useSubscription();
  const isSubscriptionsEnabled = useIsSubscriptionsEnabled();
  const shouldSelectSubscriptionBeforeTour = isSubscriptionsEnabled && !subscription;

  const [isPayDashboardTourEnabled] = useDevFeature<boolean>(FeatureFlags.IsPayDashboardTourEnabled, false);
  const {
    settings: { payDashboardBillsTabTourEnabled },
  } = useConfig();

  const { isExtraSmallScreen } = useBreakpoint();
  const { formatMessage } = useMelioIntl();

  const previousTab = usePrevious(activeTab);

  const isSwitchTabs = previousTab !== activeTab;

  const enabled =
    !shouldSelectSubscriptionBeforeTour &&
    isPayDashboardTourEnabled &&
    !isExtraSmallScreen &&
    !isSwitchTabs &&
    DASHBOARD_TOUR_SUPPORTED_TABS.includes(activeTab);

  const { steps, isLoading: isLoadingSteps } = useDashboardTourSteps({
    enabled,
    tab: activeTab,
  });

  const { data: vendorsResult } = useVendorsTabData({ searchTerm: null, enabled });
  const isExistingUser = !!vendorsResult?.data?.length;

  const analyticsProperties = {
    Flow: isExistingUser ? 'fte-existing-user' : 'fte-new-user',
    Intent: 'fte-guide',
    EntryPoint: 'login-page',
  };

  const { isTourActive, onTourIgnored, ...tourtipProps } = usePlatformTourtip({
    tourName: 'Dashboard',
    userPreferenceKey: PAY_DASHBOARD_TOUR_PREFERENCE_KEY,
    enabled,
    steps,
    defaultLabels: {
      nextButtonLabel: formatMessage('activities.payDashboard.tour.nextButton.label'),
      prevButtonLabel: formatMessage('activities.payDashboard.tour.backButton.label'),
      progressSeparator: '/',
    },
  });

  const isActiveTabTourEnabled = activeTab === PayDashboardTabs.Bills ? payDashboardBillsTabTourEnabled : true;
  const isTourtipRunning = isActiveTabTourEnabled && !!tourtipProps.run && enabled && !isLoadingSteps;

  useEffect(() => {
    onIsFirstTimeExperienceTourtipRunningChange(isTourtipRunning);
  }, [onIsFirstTimeExperienceTourtipRunningChange, isTourtipRunning]);

  useEffect(() => {
    if (isTourtipRunning && isSwitchTabs) {
      onIsFirstTimeExperienceTourtipRunningChange(false);
      onTourIgnored();
    }
  }, [onTourIgnored, isSwitchTabs, isTourtipRunning, onIsFirstTimeExperienceTourtipRunningChange]);

  return isTourtipRunning && !isLoadingSteps ? (
    <PlatformTourtip analyticsProperties={analyticsProperties} {...tourtipProps} />
  ) : null;
};
