import { useGuestPayorPaymentRequestDetails } from '@melio/ar-domain';
import { useTbt } from '@melio/form-controls';
import { withAnalyticsContext } from '@melio/platform-analytics';
import { forwardRef, useBoolean } from '@melio/platform-utils';
import { useState } from 'react';

import { useUpdatedInvoiceSystemMessage, useUserState } from '../../../hooks';
import { CardDetailsFormValues, CardHolderDetailsFormFields } from '../../types';
import { AddCardAccountDetailsScreen, AddCardAccountDetailsScreenProps } from './screens';

export type AddCardAccountDetailsActivityProps = Pick<
  AddCardAccountDetailsScreenProps,
  'onViewInvoice' | 'onSelectFundingSource' | 'isDisabled'
> & {
  paymentRequestLink: string;
  onError?: ARErrorFunction;
  onDone: (values: CardDetailsFormValues) => void;
  isNavigateApUsersToDashboardEnabled?: boolean;
  isHidden?: boolean;
};

export const AddCardAccountDetailsActivity = withAnalyticsContext<AddCardAccountDetailsActivityProps>(
  forwardRef(
    (
      {
        onError,
        paymentRequestLink,
        onDone,
        isDisabled,
        isNavigateApUsersToDashboardEnabled,
        setAnalyticsProperties,
        ...props
      },
      ref
    ) => {
      const [email, setEmail] = useState<string>();
      const paymentRequestDetailsModel = useGuestPayorPaymentRequestDetails({ paymentRequestLink });
      const userState = useUserState({ email, paymentRequestLink });
      const [isTokenizing, tokenizingState] = useBoolean();
      const isPayable = paymentRequestDetailsModel.data?.isPayable ?? true;

      useUpdatedInvoiceSystemMessage(paymentRequestLink);

      setAnalyticsProperties({ UserType: userState.userType, Email: email || undefined, PaymentMethodType: 'card' });

      const { tokenize } = useTbt();
      const onSubmit = async (values: CardHolderDetailsFormFields) => {
        tokenizingState.on();
        const {
          cardExpiration,
          cardNumber,
          cardVerificationCode,
          email,
          firstName,
          lastName,
          scheduledDate,
          ...address
        } = values;
        const { cardBrand } = cardNumber.cardMetadata!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
        return tokenize({ cardExpiration, cardNumber, cardVerificationCode })
          .then((cardParams) =>
            onDone({
              address,
              cardOwner: { firstName, lastName },
              cardParams: { ...cardParams, cardBrand },
              scheduledDate,
              email,
            })
          )
          .catch(onError)
          .finally(tokenizingState.off);
      };

      return (
        <AddCardAccountDetailsScreen
          onSubmit={onSubmit}
          isLoading={paymentRequestDetailsModel.isLoading}
          isCheckingEmail={userState.isCheckingEmail}
          paymentRequestDetails={paymentRequestDetailsModel.data}
          signInUrl={
            (isNavigateApUsersToDashboardEnabled && isPayable && userState.isApUser && userState.signInUrl) || undefined
          }
          onEmailChange={setEmail}
          isSaving={isDisabled || isTokenizing}
          {...props}
          ref={ref}
        />
      );
    }
  )
);
AddCardAccountDetailsActivity.displayName = 'AddCardAccountDetailsActivity';
