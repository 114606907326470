import { Link } from '@melio/penny';
import {
  GetContractorsInfo1099Response,
  useOrganizationPreference,
  useVendorsContractorsInfo1099,
} from '@melio/platform-api';
import { Logger } from '@melio/platform-logger';
import { useConfig, useMelioIntl, usePartnerFeature } from '@melio/platform-provider';
import { useDateUtils } from '@melio/platform-utils';

export const usePreSeason1099SectionBanner = () => {
  const [isFile1099PreSeasonBannerEnabled] = usePartnerFeature('File1099PreSeasonBanner', false);
  const { createDate } = useDateUtils();
  const { data: contractorsInfo1099 = {}, isLoading } = useVendorsContractorsInfo1099({
    enabled: isFile1099PreSeasonBannerEnabled,
  });
  const { formatMessage } = useMelioIntl();
  const {
    settings: { preSeason1099BannerLearnMoreLink },
  } = useConfig();

  const { totalContractors1099Count = 0, pendingW9RequestsCount = 0 }: GetContractorsInfo1099Response['data'] =
    contractorsInfo1099 || {};

  const {
    data: nextPreSeason1099StartDate,
    isLoading: isLoadingOrgPreferences,
    update,
  } = useOrganizationPreference({
    enabled: totalContractors1099Count > 0,
    id: 'nextPreSeason1099StartDate',
    retryOnMount: false,
  });

  const shouldHideBanner =
    !isFile1099PreSeasonBannerEnabled ||
    isLoading ||
    isLoadingOrgPreferences ||
    !totalContractors1099Count ||
    (!!nextPreSeason1099StartDate && nextPreSeason1099StartDate?.value > createDate().toISOString());

  const getNextPreSeasonDate = () => {
    const today = createDate();
    return `${today.getUTCFullYear() + 1}-11-01T00:00:00Z`;
  };

  const onCloseBanner = async () => {
    const date = getNextPreSeasonDate();
    try {
      await update({
        key: 'nextPreSeason1099StartDate',
        value: date,
      });
    } catch (error) {
      Logger.handleException(error);
    }
  };

  const bannerContent = {
    title: formatMessage(
      pendingW9RequestsCount > 0
        ? 'widgets.preSeason1099SectionBanner.withPendingW9Request.title'
        : 'widgets.preSeason1099SectionBanner.withoutPendingW9Request.title'
    ),
    description: formatMessage(
      pendingW9RequestsCount > 0
        ? 'widgets.preSeason1099SectionBanner.withPendingW9Request.description'
        : 'widgets.preSeason1099SectionBanner.withoutPendingW9Request.description',
      {
        learnMoreLink: (
          <Link
            newTab
            href={preSeason1099BannerLearnMoreLink}
            data-testid="pre-season-1099-section-banner-learn-more-link"
            label={formatMessage('widgets.preSeason1099SectionBanner.links.learnMore')}
          />
        ),
      }
    ),
  };

  return {
    onCloseBanner,
    bannerContent,
    isBannerVisible: !shouldHideBanner,
  };
};
