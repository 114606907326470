import { StatusModal, Text } from '@melio/penny';
import { useDeleteVendorGroup, useVendorGroups } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { SystemMessageDisplay, useSystemMessage } from '@melio/platform-utils';

import { DeleteVendorsGroupModalProps } from './types';

export const DeleteVendorsGroupModal = ({
  isOpen,
  onClose,
  vendorsGroupId,
  onSuccess,
}: DeleteVendorsGroupModalProps) => {
  const { formatMessage } = useMelioIntl();
  const { showMessage } = useSystemMessage();
  const { mutateAsync: deleteVendorsGroup, isLoading: isDeletingVendorsGroup } = useDeleteVendorGroup();
  const { data: vendorGroups, isLoading: isLoadingVendorsGroup } = useVendorGroups();

  const vendorsGroup = vendorGroups?.find(({ id }) => id === vendorsGroupId);

  const handleSubmit = async () => {
    try {
      await deleteVendorsGroup({ vendorGroupId: vendorsGroupId });
      showMessage({
        title: formatMessage('activities.deleteVendorsGroupModal.toasts.success', { name: vendorsGroup?.name }),
        type: 'success',
        dataTestId: 'delete-vendors-group-success',
      });
      onSuccess();
    } catch (e) {
      showMessage({
        title: formatMessage('activities.deleteVendorsGroupModal.toasts.error', { name: vendorsGroup?.name }),
        type: 'error',
        dataTestId: 'delete-vendors-group-error',
      });
    }
  };

  return (
    <StatusModal
      isOpen={isOpen}
      isLoading={isLoadingVendorsGroup}
      variant="cancel"
      header={formatMessage('activities.deleteVendorsGroupModal.header')}
      primaryButton={{
        label: formatMessage('activities.deleteVendorsGroupModal.buttons.primary'),
        onClick: handleSubmit,
        variant: 'primary',
        isLoading: isDeletingVendorsGroup,
      }}
      secondaryButton={{
        label: formatMessage('activities.createVendorsGroupModal.buttons.secondary'),
        variant: 'secondary',
        onClick: onClose,
      }}
      onClose={onClose}
      data-testid="delete-vendors-group-modal"
    >
      <SystemMessageDisplay data-testid="vendors-group-delete-modal-message" />
      <Text textStyle="body2">
        {formatMessage('activities.deleteVendorsGroupModal.description', { name: vendorsGroup?.name })}
      </Text>
    </StatusModal>
  );
};
