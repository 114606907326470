import { useDisclosure } from '@chakra-ui/react';
import {
  useIsTestMode,
  useMelioIntl,
  usePreloadApiHooks,
  useReceivingMethods,
  useSystemMessage,
} from '@melio/ar-domain';
import { withAnalyticsContext } from '@melio/platform-analytics';
import { useFundingSources } from '@melio/platform-api';
import { forwardRef } from '@melio/platform-utils';
import { Route, Routes } from 'react-router-dom';

import {
  AddReceivingMethodModalActivity,
  ShareInvoiceActivity,
  ShareTestInvoiceModalActivity,
  UploadCompanyLogoModalActivity,
} from '../activities';
import { ShareMode } from '../types';
import { useReceivingMethodsDialogActions } from './useReceivingMethodsDialogActions';
import { useShareTestInvoiceDialogActions } from './useShareTestInvoiceDialogActions';

export type ShareInvoiceFlowProps = {
  onClose: VoidFunction;
  onDone: (id: string) => void;
  onBack?: VoidFunction;
  onError?: ARErrorFunction;
  onEditInvoice: (id: string) => unknown;
  invoiceId: string;
  shareMode: ShareMode;
};

export const ShareInvoiceFlow = withAnalyticsContext<ShareInvoiceFlowProps>(
  forwardRef(
    ({ setAnalyticsProperties, onClose, onDone, onError, onEditInvoice, invoiceId, shareMode, ...props }, ref) => {
      const isTestMode = useIsTestMode();
      const receivingMethodsDialog = useReceivingMethodsDialogActions();
      const shareTestInvoiceDialog = useShareTestInvoiceDialogActions();

      // XXX cast useFundingSources to never as error handlers mismatch. fix when we use oen copy of useFundingSources
      usePreloadApiHooks([useFundingSources as never, useReceivingMethods]);

      const { triggerMessage } = useSystemMessage();
      const { formatMessage } = useMelioIntl();
      const uploadCompanyLogoDialog = useDisclosure();

      setAnalyticsProperties({
        ProductName: 'ar',
        InvoiceId: invoiceId,
        Flow: isTestMode ? 'test-invoice' : shareMode === 'reminder' ? 'send-reminder' : 'send-invoice',
      });

      return (
        <Routes>
          <Route
            path="*"
            element={
              <>
                <ShareInvoiceActivity
                  invoiceId={invoiceId}
                  onClose={onClose}
                  onBeforeDone={isTestMode ? shareTestInvoiceDialog.onOpen : receivingMethodsDialog.onOpen}
                  onUploadCompanyLogo={uploadCompanyLogoDialog.onOpen}
                  onDone={onDone}
                  shareMode={shareMode}
                  onEditInvoice={onEditInvoice}
                  {...props}
                  ref={ref}
                />
                <AddReceivingMethodModalActivity
                  isOpen={receivingMethodsDialog.isOpen}
                  onDone={receivingMethodsDialog.onDone}
                  onClose={receivingMethodsDialog.onClose}
                  onError={onError}
                />
                <ShareTestInvoiceModalActivity
                  isOpen={shareTestInvoiceDialog.isOpen}
                  onDone={shareTestInvoiceDialog.onDone}
                  onClose={shareTestInvoiceDialog.onClose}
                />
                <UploadCompanyLogoModalActivity
                  isOpen={uploadCompanyLogoDialog.isOpen}
                  onDone={uploadCompanyLogoDialog.onClose}
                  onClose={uploadCompanyLogoDialog.onClose}
                  onError={(error) => {
                    uploadCompanyLogoDialog.onClose();
                    triggerMessage({
                      type: 'critical',
                      title: formatMessage('ar.invoiceLifecycle.activities.issueInvoice.errors.failedFileUpload'),
                    });
                    onError?.(error);
                  }}
                />
              </>
            }
          />
        </Routes>
      );
    }
  )
);
ShareInvoiceFlow.displayName = 'ShareInvoiceFlow';
