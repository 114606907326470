import { Modal, useFormSubmissionController } from '@melio/penny';
import { usePaginatedVendors, useVendorGroups } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useSystemMessage } from '@melio/platform-utils';

import { usePayDashboardPagination } from '../../PayDashboard/components/Pagination';
import { VendorsGroupForm, VendorsGroupFormFields } from '../vendors-group-form';
import { CreateVendorsGroupModalProps } from './types';

export const CreateVendorsGroupModal = ({ isOpen, onClose, onSuccess }: CreateVendorsGroupModalProps) => {
  const { submitButtonProps, onSubmissionStateChange } = useFormSubmissionController<VendorsGroupFormFields>();
  const { formatMessage } = useMelioIntl();
  const { showMessage } = useSystemMessage();
  const { create, isMutating } = useVendorGroups({ enabled: false });
  const { pageSize } = usePayDashboardPagination();

  const paginationResponse = usePaginatedVendors({
    cacheTime: 0,
    params: {
      limit: pageSize,
      sort: [{ field: 'name', order: 'asc' }],
      expand: 'groups',
    },
  });

  const { isLoading } = paginationResponse;

  const handleSubmit = async (vendorsGroup: VendorsGroupFormFields) => {
    try {
      const { id: newVendorGroupId } = await create(vendorsGroup);
      showMessage({
        title: formatMessage('activities.createVendorsGroupModal.toasts.success', { name: vendorsGroup.name }),
        type: 'success',
        dataTestId: 'create-vendors-group-success',
      });
      onSuccess?.(newVendorGroupId);
      onClose();
    } catch (e) {
      showMessage({
        title: formatMessage('activities.createVendorsGroupModal.toasts.error', { name: vendorsGroup.name }),
        type: 'error',
        dataTestId: 'create-vendors-group-error',
      });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      data-testid="create-vendors-group-modal"
      header={formatMessage('activities.createVendorsGroupModal.header')}
      isLoading={isLoading}
      primaryButton={{
        label: formatMessage('activities.createVendorsGroupModal.buttons.primary'),
        variant: 'primary',
        ...submitButtonProps,
      }}
      secondaryButton={{
        label: formatMessage('activities.createVendorsGroupModal.buttons.secondary'),
        variant: 'secondary',
        onClick: onClose,
      }}
    >
      <VendorsGroupForm
        onSubmissionStateChange={onSubmissionStateChange}
        handleSubmit={handleSubmit}
        isSaving={isMutating}
      />
    </Modal>
  );
};
