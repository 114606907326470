import { Container, Group, StatusIconSolid, Text, Tooltip, Typography } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

import { DeliveryMethodChangeTableData } from './types';
import { formatDate } from './utils';

export const DeliveryMethodChangeMobileTable = ({ row }: { row: DeliveryMethodChangeTableData }) => {
  const { formatCurrency, formatMessage } = useMelioIntl();
  return (
    <>
      <Container
        overflow="initial"
        paddingX="xxs"
        key={`dm-change-mobile-row-${row?.vendor.id}`}
        data-testid={`dm-change-mobile-row-${row?.vendor.id}`}
      >
        <Group variant="horizontal" justifyContent="space-between">
          <Group variant="vertical" spacing="xxs">
            <Group variant="vertical" spacing="xxs">
              <Typography.MainLabel
                data-testid="vendor-name-label"
                label={row?.vendor?.name ?? ''}
                variant="bold"
                aria-label={row?.vendor?.name ?? ''}
              />
              <Text
                shouldSupportEllipsis
                color="semantic.text.secondary"
                textStyle="body4"
                data-testid="invoice-number"
              >
                {row?.invoiceNumber
                  ? row?.invoiceNumber
                  : formatMessage('activities.paymentFlow.form.content.deliveryMethodChangedModal.noInvoice')}
              </Text>
              <Text shouldSupportEllipsis color="global.neutral.900" textStyle="bodyMedium">
                {formatMessage('activities.paymentFlow.form.content.deliveryMethodChangedModal.mobile-table.debit-on', {
                  date: formatDate(row?.scheduleDate.value),
                })}
              </Text>
              {!!row.scheduleDate.tooltipText && (
                <Group alignItems="center">
                  <Text textStyle="body4" color="semantic.text.secondary">
                    {formatMessage(
                      'activities.paymentFlow.form.content.deliveryMethodChangedModal.table.debit-date-updates'
                    )}
                  </Text>
                  <Tooltip isEnabled={!!row.scheduleDate.tooltipText} content={row.scheduleDate.tooltipText}>
                    <StatusIconSolid variant="warning" size="small" data-testid="icon-indicator" />
                  </Tooltip>
                </Group>
              )}
              <Text shouldSupportEllipsis color="global.neutral.900" textStyle="bodyMedium">
                {formatMessage(
                  'activities.paymentFlow.form.content.deliveryMethodChangedModal.mobile-table.debit-delivery-by',
                  {
                    date: formatDate(row?.deliveryDate.value),
                  }
                )}
              </Text>
              {!!row.deliveryDate.tooltipText && (
                <Group alignItems="center">
                  <Text textStyle="body4" color="semantic.text.secondary">
                    {formatMessage(
                      'activities.paymentFlow.form.content.deliveryMethodChangedModal.table.delivery-date-updates'
                    )}
                  </Text>
                  <Tooltip isEnabled={!!row.deliveryDate.tooltipText} content={row.deliveryDate.tooltipText}>
                    <StatusIconSolid variant="warning" size="small" data-testid="icon-indicator" />
                  </Tooltip>
                </Group>
              )}
            </Group>
          </Group>
          <Group variant="vertical" spacing="xxs">
            <Text textStyle="body2Semi">{formatCurrency(Number(row?.amount))}</Text>
          </Group>
        </Group>
      </Container>
    </>
  );
};
