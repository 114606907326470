import { Button, Container, Form, Group, VisuallyHidden } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { PaymentCalendarCalculation } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useBoolean } from '@melio/platform-utils';
import { isSameDay } from 'date-fns';
import { ComponentProps, useState } from 'react';

type DateFieldProps = Omit<
  ComponentProps<typeof Form.DateField>,
  'footer' | 'isOpen' | 'selectedDate' | 'onSelectDate' | 'onOpenChange' | 'isTypable'
> & { paymentCalendarDates?: PaymentCalendarCalculation['dates'] };

const DateFieldFormatAnnouncment = ({ dateFormat }: { dateFormat: string }) => {
  const { formatMessage } = useMelioIntl();
  return (
    <VisuallyHidden>
      {formatMessage('activities.paymentFlow.form.content.deliveryDate.format.sr-only', {
        dateFormat,
      })}
    </VisuallyHidden>
  );
};

export function DateField({ value, paymentCalendarDates, onChange, ...props }: DateFieldProps) {
  const { formatMessage } = useMelioIntl();
  const { track } = useAnalytics();
  const [selectedDate, setSelectedDate] = useState(value);
  const [isCalendarOpen, calendar] = useBoolean(false);

  const dates = selectedDate
    ? paymentCalendarDates?.find((d) => isSameDay(d.minDeliveryDate, selectedDate))
    : undefined;

  return (
    <Form.DateField
      {...props}
      helperTextProps={{
        label: <DateFieldFormatAnnouncment dateFormat="MM/dd/yyyy" />,
      }}
      value={value}
      secondarySelectedDate={dates?.scheduleDate}
      disableYearSelection
      onChange={(date) => {
        if (!date || !value || !isSameDay(date, value)) {
          track('Payment', 'Click', {
            Intent: `set-payment-${props.name}`,
            PageName: 'date-picker',
            Cta: props.name,
          });
          setSelectedDate(date);
          onChange?.(date);
        }
      }}
      isOpen={isCalendarOpen}
      onOpenChange={(isOpen) => {
        if (isOpen) {
          track('Payment', 'View', {
            Intent: `set-payment-${props.name}`,
            PageName: 'date-picker',
          });
          setSelectedDate(value);
          calendar.on();
        }
      }}
      onSelectDate={(value) => setSelectedDate(value)}
      selectedDate={selectedDate}
      isTypable
      footer={
        <Container paddingX="s" paddingY="s" data-testid="deliveryDate-calendar-footer">
          <Group variant="horizontal" justifyContent="space-between">
            <Button
              data-testid="deliveryDate-calendar-footer-close"
              variant="tertiary"
              size="small"
              label={formatMessage('activities.paymentFlow.form.content.deliveryDate.footer.close')}
              onClick={() => {
                track('Payment', 'Click', {
                  Intent: `set-payment-${props.name}`,
                  PageName: 'date-picker',
                  Cta: 'close',
                });
                calendar.off();
              }}
            />
            <Button
              data-testid="deliveryDate-calendar-footer-apply"
              variant="primary"
              size="small"
              label={formatMessage('activities.paymentFlow.form.content.deliveryDate.footer.apply')}
              onClick={() => {
                if (!selectedDate) {
                  return;
                }
                track('Payment', 'Click', {
                  Intent: `set-payment-${props.name}`,
                  PageName: 'date-picker',
                  Cta: 'apply',
                });
                onChange?.(selectedDate);
                calendar.off();
              }}
            />
          </Group>
        </Container>
      }
    />
  );
}
