import { useMissingCompanyLegalInfoFields } from '@melio/ap-domain';
import { Account, LegalInfoFields, TaxIdTypeEnum, useAccount } from '@melio/platform-api';
import { useSystemMessage } from '@melio/platform-utils';

import { parseDateOfBirthToISO } from '../../../utils/pay-flow/dateOfBirth';
import { SubmissionTargetType } from '../../delivery-methods/AddVendorBankDetails/types';
import { buildLegalCompanyData } from '../CompleteLegalInfo/CompleteLegalInfo.activity.utils';

export const useInternationalAddBusinessInfo = ({
  onDone,
}: {
  onDone: (account: Account, target?: SubmissionTargetType) => void;
}): {
  missingLegalInfoFields: Array<keyof LegalInfoFields>;
  taxIdType?: TaxIdTypeEnum;
  handleDone: (completeLegalInfo: LegalInfoFields, target?: SubmissionTargetType) => void;
  isLoading: boolean;
  isUpdating: boolean;
  currentAccount: Account | undefined;
} => {
  const {
    missingUserLegalInfoFields,
    isLoading,
    data: currentAccount,
    update,
    isMutating: isUpdating,
  } = useAccount({ id: 'me' });
  const { missingCompanyLegalInfoFields } = useMissingCompanyLegalInfoFields() || {};
  const { showMessage } = useSystemMessage();

  const handleFail = (error: PlatformError) => {
    showMessage({ type: 'error', title: error.message });
  };

  const handleDone = async (
    {
      legalCompanyName,
      legalAddress,
      taxInfoIdentifier,
      taxInfoType,
      dateOfBirth,
      phoneNumber,
      industry,
      businessType,
    }: LegalInfoFields,
    target?: SubmissionTargetType
  ) => {
    try {
      const parsedDateOfBirth = dateOfBirth ? parseDateOfBirthToISO(dateOfBirth) : '';
      const companyData = buildLegalCompanyData(currentAccount, {
        legalCompanyName,
        legalAddress,
        taxInfoIdentifier,
        taxInfoType,
        phoneNumber,
        industry,
        businessType,
      });
      const userData = {
        ...(missingUserLegalInfoFields?.includes('dateOfBirth') && {
          dateOfBirth: parsedDateOfBirth !== '' ? parsedDateOfBirth : undefined,
        }),
      };
      const account = await update({
        ...(Object.keys(companyData).length && {
          company: companyData,
        }),
        ...(Object.keys(userData).length && {
          user: userData,
        }),
      });
      onDone(account, target);
    } catch (e) {
      handleFail(e as PlatformError);
    }
  };

  const missingLegalInfoFields = [...(missingCompanyLegalInfoFields || []), ...(missingUserLegalInfoFields || [])];

  return {
    handleDone,
    taxIdType: currentAccount?.company.taxInfo?.type || undefined,
    missingLegalInfoFields,
    isLoading,
    isUpdating,
    currentAccount,
  };
};
