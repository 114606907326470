import { useToast } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { ApiError, PatchAccountsAccountIdRequest, useAccountWithTaxIdentifier } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useKeydownEvent } from '@melio/platform-utils';
import { isEmpty, omitBy } from 'lodash';
import { useState } from 'react';

import { useMtlDateUtils } from '../../hooks';
import { CompleteRequiredDetailsScreen } from './complete-required-details-screen';
import { CompleteRequiredDetailsFormFields } from './CompleteRequiredDetailsForm';
import { useCompleteComplianceInformation } from './hooks';
import { SubmittedFieldsMessageType } from './types';
import { CalculateGracePeriodStatus, getFilledFields, getRequiredFields } from './utils';

export type CompleteRequiredDetailsActivityProps = {
  onBack?: VoidFunction;
  onClose: VoidFunction;
  onDone: VoidFunction;
  onError?: ErrorFunction;
  onSkip: VoidFunction;
  allowSkip?: boolean;
  isLoading?: boolean;
  submitButtonLabel: string;
  placement: 'complete-required-details' | 'one-last-thing';
};

export const CompleteRequiredDetailsActivity = withAnalyticsContext<CompleteRequiredDetailsActivityProps>(
  ({
    setAnalyticsProperties,
    onDone,
    onClose,
    onError,
    onSkip,
    onBack,
    allowSkip,
    isLoading,
    submitButtonLabel,
    placement,
  }) => {
    const { track } = useAnalytics();
    const { formatMessage } = useMelioIntl();
    const [accountUpdateError, setAccountUpdateError] = useState<PlatformError>();
    const { toast } = useToast();
    const { createDate, dateToISOString } = useMtlDateUtils();
    const [isDismissAllowed, setIsDismissAllowed] = useState<boolean>(false);

    const {
      data: account,
      isLoading: isAccountLoading,
      update: updateAccount,
      isMutating: isUpdateAccountLoading,
      error: accountError,
    } = useAccountWithTaxIdentifier({ id: 'me' });

    const {
      isLoading: isLoadingCompleteComplianceInformation,
      error: complianceInformationError,
      missingFields,
      isGracePeriodStarted,
      isGracePeriodEnded,
      numberOfPaymentsLeftInGracePeriod,
      invalidateQuery: invalidateAccountLimitationsQuery,
    } = useCompleteComplianceInformation({ isInitiator: true });

    setAnalyticsProperties({
      PageName: 'complete-the-required-details',
    });

    const handleUpdateAccount = async (
      payload: PatchAccountsAccountIdRequest,
      submittedFieldsType: SubmittedFieldsMessageType | null
    ) => {
      try {
        track('Dashboard', 'Saved');
        setAccountUpdateError(undefined);
        await updateAccount(payload);
        void invalidateAccountLimitationsQuery();
        onDone();
        submittedFieldsType &&
          toast({
            type: 'success',
            title: formatMessage(`widgets.completeRequiredDetailsModal.update.message.success.${submittedFieldsType}`),
          });
      } catch (e) {
        const error: PlatformError = {
          message: (e as Error).message || formatMessage('screens.generalError.title'),
          code: (e as ApiError).code,
        };
        setAccountUpdateError(error);
        onError?.(error);
        track('Dashboard', 'Failure');
        toast({
          type: 'error',
          title: error.message,
        });
      }
    };

    const handleSubmit = (data: CompleteRequiredDetailsFormFields) => {
      const { filledFields, numFilledOrgFields, numFilledUserFields } = getFilledFields(missingFields, data);
      const { requiredFields, numberOfRequiredUserFields, numberOfRequiredOrgFields } =
        getRequiredFields(missingFields);

      const getSubmittedFieldsMessageType = () => {
        if (!numFilledOrgFields && numFilledUserFields) {
          return SubmittedFieldsMessageType.PERSONAL_ONLY;
        }
        if (numFilledOrgFields && !numFilledUserFields) {
          return SubmittedFieldsMessageType.COMPANY_ONLY;
        }
        if (!numFilledOrgFields && !numFilledUserFields) {
          return null;
        }
        return SubmittedFieldsMessageType.GENERIC;
      };

      const submittedFieldsMessageType = getSubmittedFieldsMessageType();

      void handleUpdateAccount(
        {
          company: omitBy(
            {
              contactFirstName: data.contactFirstName,
              contactLastName: data.contactLastName,
              name: data.companyName,
              legalName: data.legalCompanyName,
              address: data.address && { ...data.address, line2: data.address.line2 || '' },
              legalAddress: data.legalAddress && { ...data.legalAddress, line2: data.legalAddress.line2 || '' },
              taxInfo:
                data.taxInfoType ?? data.taxInfoIdentifier
                  ? {
                      ...(data.taxInfoType ? { type: data.taxInfoType } : undefined),
                      ...(data.taxInfoIdentifier ? { identifier: data.taxInfoIdentifier } : undefined),
                    }
                  : undefined,
              phoneNumber: data.phoneNumber,
              industry: data.industry,
              businessType: data.businessType,
              legalDateOfBirth: data.legalDateOfBirth && dateToISOString(createDate(data.legalDateOfBirth)),
            },
            isEmpty
          ),
          user: omitBy(
            {
              firstName: data.firstName,
              lastName: data.lastName,
              ...(data.dateOfBirth && { dateOfBirth: dateToISOString(createDate(data.dateOfBirth)) }),
            },
            isEmpty
          ),
        },
        submittedFieldsMessageType
      );

      track('Organization', 'Status', {
        PageName: 'dashboard-vendors',
        Intent: 'submit-details',
        GracePeriodStatus: CalculateGracePeriodStatus(isGracePeriodStarted, isGracePeriodEnded),
        // GracePeriodDaysRemain: '', // TODO: should complete on phase 2
        GracePeriodPaymentsRemain: numberOfPaymentsLeftInGracePeriod,
        NumRequiredUserFields: numberOfRequiredUserFields,
        NumRequiredOrgFields: numberOfRequiredOrgFields,
        RequiredFields: requiredFields,
        NumFilledUserFields: numFilledUserFields,
        NumFilledOrgFields: numFilledOrgFields,
        FilledFields: filledFields,
        Status: 'success',
      });

      track('VerifyTaxDetails', 'Submitted');
    };

    const isLoadingScreen = isLoading || isAccountLoading || isLoadingCompleteComplianceInformation;
    const error = accountError || complianceInformationError || accountUpdateError;

    const isDismissible = !error && !isLoadingCompleteComplianceInformation && isDismissAllowed;
    const isSkippable = !error && !isLoadingCompleteComplianceInformation && !!allowSkip;

    useKeydownEvent({
      key: 'Escape',
      handler: () => onClose(),
      enabled: isDismissible,
    });

    return (
      <CompleteRequiredDetailsScreen
        missingFields={missingFields}
        isSaving={isUpdateAccountLoading}
        isLoading={isLoadingScreen}
        onDone={handleSubmit}
        account={account}
        onSkip={onSkip}
        onClose={onClose}
        onBack={onBack}
        isDismissible={isDismissible}
        isSkippable={isSkippable}
        setIsDismissAllowed={setIsDismissAllowed}
        error={error?.message}
        submitButtonLabel={submitButtonLabel}
        numberOfPaymentsLeftInGracePeriod={numberOfPaymentsLeftInGracePeriod}
        isGracePeriodEnded={isGracePeriodEnded}
        isGracePeriodStarted={isGracePeriodStarted}
        placement={placement}
      />
    );
  }
);
