/* eslint-disable react-hooks/exhaustive-deps */
import React, { RefObject, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Flex } from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { DeprecatedTabs, IconButton } from '@melio/penny';
import { useAnalyticsContext, withAnalyticsContext } from '@melio/platform-analytics';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useBoolean } from '@melio/platform-utils';

import { ExpandableFormSearch } from '@/cl/components/ExpandableFormSearch/ExpandableFormSearch.component';
import { FormSearch } from '@/cl/components/FormSearch/FormSearch.component';
import { PayListActiveTab } from '@/components/pay-dashboard/PayListActiveTab';
import { PayListTabHeader } from '@/components/pay-dashboard/PayListTabHeader';
import { WithLoading } from '@/hoc/withLoading.hoc';
import { usePayDashboardTabView } from '@/hooks/analytics/payDashboardAnalytics.hooks';
import { usePayDashboardHandleNavigation, usePayDashboardItems } from '@/hooks/payDashboard.hooks';
import { useRouter } from '@/hooks/router.hooks';
import {
  payDashboardItemSelectionsSelector,
  payDashboardQuerySearchSelector,
  payDashboardSelectedCardSelector,
} from '@/store/PayDashboard/PayDashboards.model';
import { usePlatformIntl } from '@/translations/Intl';
import { PayDashboardTabs } from '@/types/payDashboard.types';

export const PayDashboardBillListScreen = withAnalyticsContext<{ isLoading?: boolean }>(({ isLoading }) => {
  const { tab } = useParams<{ tab: PayDashboardTabs }>();
  const { isLoading: isPayDashboardItemsLoading, groups } = usePayDashboardItems();
  const { formatMessage } = usePlatformIntl();
  const { pushPayDashboardTab, goToPathname } = useRouter();
  const [isExpandingFormSearchEnabled] = useDevFeature<boolean>(FeatureFlags.ExpandingFormSearch, false);
  const setSelectedCard = useSetRecoilState(payDashboardSelectedCardSelector);
  usePayDashboardTabView(PayDashboardTabs.Inbox);
  const setItemSelections = useSetRecoilState(payDashboardItemSelectionsSelector);

  const tabs = [
    {
      label: formatMessage(`app.payDashboard.tabs.labels.${PayDashboardTabs.Inbox}`),
      name: PayDashboardTabs.Inbox,
    },
    {
      label: formatMessage(`app.payDashboard.tabs.labels.${PayDashboardTabs.Scheduled}`),
      name: PayDashboardTabs.Scheduled,
    },
    {
      label: formatMessage(`app.payDashboard.tabs.labels.${PayDashboardTabs.Paid}`),
      name: PayDashboardTabs.Paid,
    },
  ];

  const [activeTab, setActiveTab] = React.useState<PayDashboardTabs>(tab || tabs[0].name);

  useAnalyticsContext({ globalProperties: { dashboardTab: activeTab } });

  usePayDashboardHandleNavigation(activeTab);
  const [querySearch, setQuerySearch] = useRecoilState(payDashboardQuerySearchSelector);

  const onTabChange = React.useCallback(
    (tabName: string) => {
      if (!Object.values(PayDashboardTabs).includes(tabName as PayDashboardTabs)) {
        return;
      }

      const verifiedTabName = tabName as PayDashboardTabs;
      if (verifiedTabName === activeTab) {
        goToPathname();
      } else {
        setSelectedCard({});
        setActiveTab(verifiedTabName as PayDashboardTabs);
        pushPayDashboardTab(verifiedTabName, '');
      }
    },
    [setActiveTab, activeTab],
  );

  React.useEffect(() => {
    if (tab && Object.values(PayDashboardTabs).includes(tab)) {
      setActiveTab(tab);
    }
  }, [tab]);

  React.useEffect(
    () => () => {
      setItemSelections([]);
    },
    [setItemSelections],
  );

  const [isExpanded, expanded] = useBoolean(false);
  const [searchInput, setSearchInput] = useState<string>('');
  const iconButtonRef = useRef<HTMLButtonElement>(null);

  const onOutsideClickHandler = (event: Event) => {
    if (!iconButtonRef?.current?.contains(event.target as HTMLElement)) {
      isExpanded && !searchInput && expanded.off();
    }
  };

  const handleEscKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Escape') {
      isExpanded && !searchInput && expanded.off();
    }
  };

  const isEmptyInboxState =
    activeTab === PayDashboardTabs.Inbox && groups[PayDashboardTabs.Inbox].length === 0 && isEmpty(querySearch);

  return (
    <Flex data-testid="pay-dashboard-bill-list" direction={'column'} margin={'auto'} height={'full'}>
      {!isExpandingFormSearchEnabled && (
        <Box mb={{ xs: '24px', s: '40px' }}>
          <FormSearch onSearch={setQuerySearch} placeholder={formatMessage('app.payDashboard.search.placeholder')} />
        </Box>
      )}
      <WithLoading isLoading={isLoading || isPayDashboardItemsLoading} isAbsoluteCenter>
        <PayListTabHeader shouldShowNewPaymentButton={!isEmptyInboxState} />
        {isExpandingFormSearchEnabled ? (
          <Box onKeyDown={handleEscKeyPress}>
            <Flex justifyContent={'space-between'} mb="24px">
              <DeprecatedTabs activeTab={activeTab} onChange={onTabChange} tabs={tabs} />
              <SearchIconButton
                iconButtonRef={iconButtonRef}
                onClick={() => {
                  expanded.toggle();
                }}
              />
            </Flex>
            <ExpandableFormSearch
              isExpanded={isExpanded}
              onSearch={setQuerySearch}
              onSearchInput={setSearchInput}
              onOutsideClickHandler={onOutsideClickHandler}
              placeholder={formatMessage('app.payDashboard.search.placeholder')}
            />
          </Box>
        ) : (
          <Box mb="24px">
            <DeprecatedTabs activeTab={activeTab} onChange={onTabChange} tabs={tabs} />
          </Box>
        )}
        <PayListActiveTab activeTab={activeTab} groups={groups} />
      </WithLoading>
    </Flex>
  );
});

const SearchIconButton = ({
  onClick,
  iconButtonRef,
}: {
  onClick: () => void;
  iconButtonRef: RefObject<HTMLButtonElement>;
}) => (
  <IconButton
    aria-label={'search-icon'}
    size="medium"
    variant={'naked'}
    icon={'search'}
    data-testid={'form-search-icon'}
    onClick={onClick}
    ref={iconButtonRef}
  />
);
