import { useBreakpointValue } from '@chakra-ui/react';
import { Group, GroupProps, Illustration } from '@melio/penny';
import { useAnalytics, useAnalyticsView, withAnalyticsContext } from '@melio/platform-analytics';
import { FormattedMessage } from '@melio/platform-i18n';
import { useMonitoring } from '@melio/platform-monitoring';
import { useCallback, useEffect } from 'react';

import { MonitoredAction } from '../../../../../monitoring';
import { NewSinglePaymentStepLayout } from '../../../../NewSinglePaymentStepLayout';
import { PaymentScheduledPromotions } from '../../../../PaymentScheduled/screens/PaymentsScheduled/components/PaymentScheduledPromotions';
import { PaymentScheduledRiskDisclaimer } from '../../../../PaymentScheduled/screens/PaymentsScheduled/components/RiskDisclaimer';
import { PaymentScheduledUpsells } from '../../../../PaymentScheduled/screens/PaymentsScheduled/components/Upsells';
import {
  PromoteAccountingSoftwareSyncBanner,
  useShowPromoteAccountingSoftwareSyncBanner,
} from '../../../../promote-accounting-software-sync';
import { usePromotionsData } from '../../../../promotions';
import { RecurringPaymentScheduledActionButtons } from '../../../screens/RecurringPaymentScheduled/RecurringPaymentScheduledActionButtons';
import { RecurringPaymentScheduledSuccessfullyScreenProps } from '../../types';
import { RecurringPaymentDetailsCard } from './components/RecurringPaymentDetailsCard';

export const RecurringPaymentScheduledSuccessfullyScreen =
  withAnalyticsContext<RecurringPaymentScheduledSuccessfullyScreenProps>(
    ({
      notifyVendor,
      subscriptionDetails,
      onClose,
      onDone,
      onNotifyVendors,
      isLoading,
      setAnalyticsProperties,
      ...props
    }) => {
      const paymentsIds = subscriptionDetails.paymentIds || [];

      const {
        isLoading: isPromotionsLoading,
        analyticsData: promotionsAnalyticsData,
        activePromotion,
      } = usePromotionsData();

      const { trackMarketing } = useAnalytics();

      setAnalyticsProperties({
        BillSubscriptionId: subscriptionDetails.billSubscriptionId,
      });
      const { viewAnalyticsProps } = useShowPromoteAccountingSoftwareSyncBanner({ dismissScope: null });

      useAnalyticsView('Payment', !isPromotionsLoading, false, {
        PaymentId: paymentsIds,
        Feature: promotionsAnalyticsData,
        ...viewAnalyticsProps,
      });

      const { endAction } = useMonitoring<MonitoredAction>();
      const endActionMonitoring = useCallback(() => {
        endAction('review_and_confirm');
        endAction('complete_legal_info');
      }, [endAction]);

      useEffect(() => {
        trackMarketing('pay-bill_recurring-confirm-success');
      }, [trackMarketing]);

      const footerGap = useBreakpointValue<GroupProps['spacing']>({ xs: 'm', s: 'm', m: 'm' });
      const actionSectionWidth = useBreakpointValue<GroupProps['width']>({ xs: 'full', s: 'full', m: 'full' });
      const actionSectionVariant = useBreakpointValue<GroupProps['variant']>({
        xs: 'vertical',
        s: 'horizontal',
        m: 'horizontal',
      });

      return (
        <NewSinglePaymentStepLayout
          data-component="RecurringPaymentScheduledActivity.RecurringPaymentScheduledSuccessfullyScreen"
          data-testid="recurring-payment-scheduled-activity-recurring-payment-scheduled-successfully-screen"
          isLoading={isLoading}
          ref={endActionMonitoring}
          {...props}
          footerContent={
            <NewSinglePaymentStepLayout.Actions borderTop="none">
              <Group
                variant={actionSectionVariant}
                spacing={footerGap}
                width={actionSectionWidth}
                justifyContent="center"
              >
                <RecurringPaymentScheduledActionButtons
                  onDone={onDone}
                  onNotifyVendors={onNotifyVendors}
                  notifyVendor={notifyVendor}
                  isNewSuccessScreen
                />
              </Group>
            </NewSinglePaymentStepLayout.Actions>
          }
          bottomContent={
            <NewSinglePaymentStepLayout.Actions borderTop="none">
              <PaymentScheduledRiskDisclaimer vendors={[subscriptionDetails.vendor]} />
            </NewSinglePaymentStepLayout.Actions>
          }
        >
          <Group variant="vertical" alignItems="center">
            <Illustration type="success" size="large" />
            <NewSinglePaymentStepLayout.Title textAlign="center">
              <FormattedMessage id="activities.recurringPaymentScheduled.screens.recurringPaymentScheduledSuccessfullyScreen.title" />
            </NewSinglePaymentStepLayout.Title>
          </Group>
          <RecurringPaymentDetailsCard subscriptionDetails={subscriptionDetails} />
          <PaymentScheduledUpsells vendorId={subscriptionDetails.vendor.id} />
          <PaymentScheduledPromotions
            flow="recurring-payment"
            promotionName={activePromotion}
            paymentIds={paymentsIds}
            onDone={onDone}
          />
          <PromoteAccountingSoftwareSyncBanner dismissScope={null} analyticsProps={{ PageName: 'payment-scheduled' }} />
        </NewSinglePaymentStepLayout>
      );
    }
  );

RecurringPaymentScheduledSuccessfullyScreen.displayName =
  'RecurringPaymentScheduledActivity.RecurringPaymentScheduledSuccessfullyScreen';
