import { isPaymentScheduledVoidToRefund } from '@melio/ap-activities/src/utils/pay-flow/Payment.utils';
import { usePaymentProcessingInfo } from '@melio/ap-domain';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { MessageKey } from '@melio/platform-i18n';

import { usePlatformIntl } from '@/translations/Intl';
import { PaymentDetailsProps } from '@/widgets/pay-dashboard/payment-details/PaymentDetails.types';

type TrackerStepData = {
  title: string;
  description?: string;
  invalid?: string;
};

type PaymentTimelineTracker = {
  shouldShowPaymentTimeLineTracker?: boolean;
  steps?: TrackerStepData[];
  currentStepIndex?: number;
};

type TimeLineStepDates = {
  firstStepDate?: Date;
  secondStepDate?: Date;
  thirdStepDate?: Date;
  isLateDeposit?: boolean;
};

export const getStepLabelPrefix = (payment: PaymentDetailsProps['payment']): string => {
  const isRefund = payment?.metadata?.isRefund;
  const isVoidingToRefund = isPaymentScheduledVoidToRefund(payment);

  if (isRefund || isVoidingToRefund) {
    return 'refund';
  }

  const deliveryMethodType = payment.deliveryMethod?.type;

  if (deliveryMethodType === 'virtual-card') {
    return deliveryMethodType;
  }

  return 'default';
};

const extractStepDates = (
  payment: PaymentDetailsProps['payment'],
  { isPaymentProcessedExternally }: { isPaymentProcessedExternally: boolean },
): TimeLineStepDates => {
  const isRefund = payment.metadata?.isRefund;
  const isVoidingToRefund = payment.metadata?.isVoidingToRefund;

  if (isVoidingToRefund) {
    return {
      firstStepDate: payment.processedTimeline?.voidCheckInitiated,
      secondStepDate: payment.processedTimeline?.refundSent,
      thirdStepDate: payment.processedTimeline?.refundCompleted,
      isLateDeposit: payment.processedTimeline?.isLateDeposit,
    };
  }

  if (isRefund) {
    return {
      firstStepDate: payment.processedTimeline?.refundInitiated,
      secondStepDate: payment.processedTimeline?.refundSent,
      thirdStepDate: payment.processedTimeline?.refundCompleted,
      isLateDeposit: payment.processedTimeline?.isLateDeposit,
    };
  }

  if (isPaymentProcessedExternally) {
    return {
      firstStepDate: payment.processedTimeline?.collectDate,
      secondStepDate: payment.processedTimeline?.collectDate,
      thirdStepDate: payment.processedTimeline?.depositedDate,
      isLateDeposit: payment.processedTimeline?.isLateDeposit,
    };
  }

  return {
    firstStepDate: payment.processedTimeline?.collectDate,
    secondStepDate: payment.processedTimeline?.inTransitDate,
    thirdStepDate: payment.processedTimeline?.depositedDate,
    isLateDeposit: payment.processedTimeline?.isLateDeposit,
  };
};

const usePaymentTimelineTrackerProps = (
  payment: PaymentDetailsProps['payment'],
): Pick<PaymentTimelineTracker, 'steps' | 'currentStepIndex'> | undefined => {
  const { formatMessage, formatDate } = usePlatformIntl();
  const { isPaymentProcessedByCapitalOne } = usePaymentProcessingInfo();

  const { firstStepDate, secondStepDate, thirdStepDate, isLateDeposit } = extractStepDates(payment, {
    isPaymentProcessedExternally: isPaymentProcessedByCapitalOne(payment.deliveryMethod?.type),
  });

  const missingFirstStep = !firstStepDate;
  const missingSecondStep = firstStepDate && !secondStepDate && thirdStepDate;

  const missingAllSteps = !firstStepDate && !secondStepDate && !thirdStepDate;

  if (missingFirstStep || missingSecondStep || missingAllSteps) return;

  const stepLabelPrefix = getStepLabelPrefix(payment);
  const thirdStepDescriptionPrefix = isLateDeposit ? 'late' : 'default';

  const firstStep = {
    title: formatMessage(`widgets.paymentDetails.timelineTracker.label.${stepLabelPrefix}.firstStep` as MessageKey),
    description: formatDate(firstStepDate, { dateStyle: 'medium' }),
  };

  const secondStep = {
    title: formatMessage(`widgets.paymentDetails.timelineTracker.label.${stepLabelPrefix}.secondStep` as MessageKey),
    description: secondStepDate ? formatDate(secondStepDate, { dateStyle: 'medium' }) : undefined,
  };

  const thirdStep = {
    title: formatMessage(`widgets.paymentDetails.timelineTracker.label.${stepLabelPrefix}.thirdStep` as MessageKey),
    description: thirdStepDate
      ? formatMessage(`widgets.paymentDetails.timelineTracker.description.${thirdStepDescriptionPrefix}`, {
          date: thirdStepDate,
        })
      : undefined,
  };

  const steps = [firstStep, secondStep, thirdStep];
  const currentStepIndex = thirdStepDate ? 2 : secondStepDate ? 1 : 0;
  return { steps, currentStepIndex };
};

export const usePaymentTimelineTracker = (payment: PaymentDetailsProps['payment']): PaymentTimelineTracker => {
  const [isTimelineTrackerEnabled] = useDevFeature(FeatureFlags.TimelineTrackerEnabled, false);

  const trackerProps = usePaymentTimelineTrackerProps(payment);

  const shouldShowPaymentTimeLineTracker = isTimelineTrackerEnabled || Boolean(trackerProps);

  return {
    shouldShowPaymentTimeLineTracker,
    steps: trackerProps?.steps,
    currentStepIndex: trackerProps?.currentStepIndex,
  };
};
