import { Logger } from '@melio/platform-logger';
import { maxBy } from 'lodash';
import { useCallback } from 'react';

import { Plan, Tier } from '../api';
import { useSubscriptionContext } from '../context';
import { isAccountantFirmClientPlanTier, isAccountantFirmPlanTier, plansSorter, TIERS_ORDER } from '../utils';
import { FeatureRequest, getIsFeatureInPlan } from '../utils/planFeatures.utils';

const isMostPopularPlan = (plan: Plan) => plan.tier === Tier.MelioPlus;
const isFreePlan = (plan: Plan) => plan.cycles.monthly.basePrice === 0;
const isPaidPlan = (plan: Plan) => !isFreePlan(plan);

export const usePlansTiers = () => {
  const { plans: allPlans, subscription } = useSubscriptionContext();

  const getPlan = useCallback(
    (planId: string) => {
      const plan = allPlans.find((plan) => plan.id === planId);
      if (!plan) {
        const error = `plan not found. planId: ${planId}`;
        Logger.log(error, 'error');
        throw new Error(error);
      }
      return plan;
    },
    [allPlans]
  );

  const getPlanTier = useCallback((planId: string) => TIERS_ORDER[getPlan(planId).tier], [getPlan]);

  const getSmbPlans = useCallback(
    () => allPlans.filter((plan) => !isAccountantFirmPlanTier(plan.tier) && !isAccountantFirmClientPlanTier(plan.tier)),
    [allPlans]
  );

  const getClientPlans = useCallback(
    () => allPlans.filter((plan) => isAccountantFirmClientPlanTier(plan.tier)).sort(plansSorter),
    [allPlans]
  );

  const getIsPlanAccountingClientPlan = useCallback(
    (planId: string) => isAccountantFirmClientPlanTier(getPlan(planId).tier),
    [getPlan]
  );

  const getAvailablePlans = useCallback(() => {
    if (subscription?.planId && getIsPlanAccountingClientPlan(subscription.planId)) {
      return getClientPlans();
    }
    return getSmbPlans();
  }, [subscription, getSmbPlans, getClientPlans, getIsPlanAccountingClientPlan]);

  const getIsFreePlan = useCallback((planId: string) => isFreePlan(getPlan(planId)), [getPlan]);

  const getIsMostPopularPlan = useCallback((planId: string) => isMostPopularPlan(getPlan(planId)), [getPlan]);

  const getNextPlanWithUnlimitedSeats = useCallback(
    (planId: string) =>
      getAvailablePlans().find((plan) => getPlanTier(plan.id) > getPlanTier(planId) && plan.cycles.monthly.unitPrice),
    [getAvailablePlans, getPlanTier]
  );

  const getFirstPaidPlan = useCallback(() => getAvailablePlans().find(isPaidPlan), [getAvailablePlans]);

  const getFreePlan = useCallback(() => allPlans.find(isFreePlan), [allPlans]);

  const getFirstPlanWithFeature = useCallback(
    (feature: FeatureRequest) => {
      const firstPlanWithFeature = getAvailablePlans().find((plan) => getIsFeatureInPlan(plan, feature).isEligible);

      if (!firstPlanWithFeature) {
        const error = `a plan with the feature not found. featureName: ${feature.featureName}`;
        Logger.log(error, 'error');
        throw new Error(error);
      }

      return firstPlanWithFeature;
    },
    [getAvailablePlans]
  );

  const getIsMostExpensivePlan = useCallback(
    (plan: Plan) => {
      const highestPlanPrice = maxBy(getAvailablePlans(), (plan) => plan.cycles.monthly.basePrice)?.cycles.monthly
        .basePrice;
      return plan.cycles.monthly.basePrice === highestPlanPrice;
    },
    [getAvailablePlans]
  );

  return {
    getIsMostExpensivePlan,
    getAvailablePlans,
    getFreePlan,
    getFirstPaidPlan,
    getIsFreePlan,
    getPlan,
    getPlanTier,
    getClientPlans,
    getSmbPlans,
    getIsMostPopularPlan,
    getNextPlanWithUnlimitedSeats,
    getFirstPlanWithFeature,
    getIsPlanAccountingClientPlan,
  };
};
