import { useRoleText } from '@melio/ap-domain';
import { roleToVariant } from '@melio/ap-domain';
import { RoleUniqueNames } from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useConfig } from '@melio/platform-provider';

import { PaymentFullyExpanded } from '@/api/payments/payments.api.types';

export const usePaymentScheduledByProps = (payment: PaymentFullyExpanded) => {
  const [accountManagerAccessCollaborators] = useDevFeature<boolean>(
    FeatureFlags.AccountManagerAccessCollaborators,
    false,
  );
  const config = useConfig();
  const showPaymentScheduledBySection =
    accountManagerAccessCollaborators && config.settings.payment.showCreatedByIndication;
  const { getRoleText } = useRoleText();
  const paymentCreatorRole = payment.createdBy?.role as RoleUniqueNames;

  return {
    showPaymentScheduledBySection,
    firstName: payment.createdBy?.firstName,
    lastName: payment.createdBy?.lastName,
    role: getRoleText(paymentCreatorRole || null),
    roleVariant: roleToVariant(paymentCreatorRole || null),
    createdAt: payment.history.createdAt,
  };
};
