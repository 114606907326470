import { Route, Routes } from 'react-router-dom';
import { useAccountantsRoutes } from '@melio/accountants';
import {
  AccountingClientSubscriptionRoutes,
  DowngradeSubscriptionRoutes,
  SubscriptionGraceRoutes,
  SubscriptionPlansRoute,
} from '@melio/subscriptions';
import { SubscriptionFlowsFullScreen } from '@melio/subscriptions/src/ui';

import { useSwitchOrganization } from '@/hooks/useSwitchOrganization.hooks';
import { FullScreenLayout } from '@/router/layouts/FullScreen.layout';
import { OnlyHeaderLayout } from '@/router/layouts/OnlyHeader.layout';

export const SubscriptionsRouter = () => {
  const { switchOrganization } = useSwitchOrganization();
  const { goToAssignClientPlan } = useAccountantsRoutes();

  return (
    <Routes>
      <Route element={<FullScreenLayout showOrgSwitcher />}>
        <Route path="accounting-client-plan-selected/*" element={<AccountingClientSubscriptionRoutes />} />
        <Route path="finish-setup/*" element={<SubscriptionFlowsFullScreen />} />
      </Route>
      <Route path="/*" element={<SubscriptionPlansRoute />} />
      <Route element={<OnlyHeaderLayout />}>
        <Route path="downgrade/*" element={<DowngradeSubscriptionRoutes />} />
        <Route
          path="grace/*"
          element={
            <SubscriptionGraceRoutes
              switchOrganization={({
                organizationId,
                skipPayDashboardRedirect,
              }: {
                organizationId: string;
                skipPayDashboardRedirect?: boolean;
              }) => switchOrganization({ organizationId, skipPayDashboardRedirect, isAccountingFirm: false })}
              goToAssignClientPlan={({ accountingClientId }) =>
                goToAssignClientPlan({ accountingClientId, refresh: true })
              }
            />
          }
        />
      </Route>
    </Routes>
  );
};
