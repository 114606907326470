import { union } from 'lodash';
import { useRecoilState, useRecoilValue } from 'recoil';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';

import { GroupItemCard, PayCardTagStatus } from '@/cl/components/PayCard/PayCard.component';
import { useIsMobile } from '@/hooks/useIsMobile';
import { useShowConfirmationBar } from '@/store/ConfirmationBar/ConfirmationBar.model';
import {
  payDashboardItemSelectionsSelector,
  payDashboardQuerySearchSelector,
} from '@/store/PayDashboard/PayDashboards.model';
import { usePlatformIntl } from '@/translations/Intl';
import { GroupItemType } from '@/types/payDashboard.types';
import { BaseBillCardProps } from '@/widgets/pay-dashboard/cards/types/PaymentCard.types';
import { isPartiallyPaidBill } from '../../../../utils/Bill.utils';
import { useTrackBatchSelection } from '../useTrackBatchSelection';

export const BillPayCard = ({ bill, vendorName }: BaseBillCardProps) => {
  const { formatMessage, formatDate } = usePlatformIntl();
  const highlightedText = useRecoilValue(payDashboardQuerySearchSelector);
  const [itemSelections, setItemSelections] = useRecoilState(payDashboardItemSelectionsSelector);
  const [isBatchPaymentsEnabled] = useDevFeature(FeatureFlags.BatchPayments, false);

  const isMobile = useIsMobile();
  const { dueDate, amount, balance } = bill;

  const { trackBatchSelection } = useTrackBatchSelection();

  const showConfirmationBar = useShowConfirmationBar();

  const handleItemChecked = (checked: boolean) => {
    trackBatchSelection({
      BillId: bill.id,
      VendorId: bill.vendorId,
      Amount: amount,
      DueDate: dueDate,
      selected: itemSelections.length,
      action: checked ? 'add' : 'remove',
    });

    showConfirmationBar(false);

    setItemSelections((currVal) => (checked ? union(currVal, [bill.id]) : currVal.filter((id) => id !== bill.id)));
  };

  const getTagByType = (): { tagText: string; status: PayCardTagStatus } | null => {
    return isPartiallyPaidBill(bill)
      ? {
          tagText: formatMessage('widgets.payCard.badgeByType.paidPartial'),
          status: 'neutral',
        }
      : null;
  };

  const dateText = formatMessage('widgets.payCard.dueDateText', {
    date: formatDate(dueDate, { dateStyle: 'medium' }),
  });

  return (
    <GroupItemCard
      itemType={GroupItemType.UNPAID}
      topTag={getTagByType()}
      vendorName={vendorName}
      amount={balance}
      dateText={dateText}
      bills={[bill]}
      highlightedText={highlightedText}
      shouldShowCheckbox={isBatchPaymentsEnabled && !isMobile}
      isChecked={itemSelections.includes(bill.id)}
      onCheck={handleItemChecked}
    />
  );
};

BillPayCard.displayName = 'BillPayCard';
