import { useNonEntityMutation } from '@melio/api-client';
import { ApiKeys, SubscriptionsApiClient } from '@melio/platform-api-axios-client';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

type QueryFN = (_?: string) => ReturnType<typeof SubscriptionsApiClient.acceptDowngrade>;

export const useAcceptSubscriptionDowngrade = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const acceptDowngrade = useNonEntityMutation<QueryFN, void>(
    SubscriptionsApiClient.acceptDowngrade,
    [ApiKeys.SubscriptionsApi, 'collection', 'acceptDowngrade'],
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries('SubscriptionsApi');
        navigate('/pay-dashboard');
      },
      onError: () => {
        navigate('/error');
      },
    }
  );

  return {
    acceptDowngrade: acceptDowngrade.mutateAsync,
    isLoading: acceptDowngrade.isLoading,
    isError: acceptDowngrade.isError,
  };
};
