import { useVendors } from '@melio/platform-api';
import { isEmpty } from 'lodash';
import { useState } from 'react';

type PartialFormData = Record<string, unknown>;

type Props = {
  vendorId?: string;
  isVendorUniqueNameEnabled: boolean;
};

export const useVendorUniqueNameUpdate = ({ vendorId, isVendorUniqueNameEnabled }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState<string>('');
  const [mode, setMode] = useState<'edit' | 'confirm'>('confirm');
  const { data: vendors, isLoading } = useVendors({ enabled: isVendorUniqueNameEnabled });

  const shouldShowModal = (formData: PartialFormData, isExternalFieldVisible?: boolean) => {
    const companyName = formData?.['companyName'] as string;
    const accountNumber = formData?.['accountNumber'] as string;
    const isUniqueNameExists = !isEmpty(formData?.['uniqueName']);
    const vendorToUpdate = vendors?.find((vendor) => vendor.id === vendorId);
    const isInitiallySyncedVendor = vendorToUpdate ? vendorToUpdate.name === vendorToUpdate.uniqueName : false;
    const isCompanyNameUnique = !vendors?.length || !vendors.some((vendor) => vendor.name === companyName);
    const isCompanyOrUniqueNameUnique =
      !vendors?.length || !vendors.some((vendor) => vendor.name === companyName || vendor.uniqueName === companyName);
    const shouldShowModalInConfirmMode = isVendorUniqueNameEnabled && isUniqueNameExists && isCompanyNameUnique;
    const shouldShowModalInEditMode =
      isVendorUniqueNameEnabled && !isInitiallySyncedVendor && !isCompanyOrUniqueNameUnique && !isExternalFieldVisible;

    if (shouldShowModalInEditMode) {
      setMode('edit');
      setValue(`${companyName.slice(0, 25)} (${accountNumber.toString().slice(-4)})`);
      return true;
    }

    if (shouldShowModalInConfirmMode) {
      setMode('confirm');
      setValue(companyName);
      return true;
    }

    return false;
  };

  const onClose = () => {
    setIsOpen(false);
  };

  return {
    mode,
    value,
    isOpen,
    isLoading,
    onOpen: () => setIsOpen(true),
    onClose,
    shouldShowModal,
  };
};
