import { Button, Group, Modal, useBreakpoint, useFormSubmissionController } from '@melio/penny';
import { usePaginatedVendors, useUpdateVendorGroup, useVendorGroups } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useSystemMessage } from '@melio/platform-utils';

import { usePayDashboardPagination } from '../../PayDashboard/components/Pagination';
import { VendorsGroupForm, VendorsGroupFormFields } from '../vendors-group-form';
import { EditVendorsGroupModalProps } from './types';

export const EditVendorsGroupModal = ({
  isOpen,
  onClose,
  vendorsGroupId,
  onDeleteVendorsGroupClick,
}: EditVendorsGroupModalProps) => {
  const { submitButtonProps, onSubmissionStateChange } = useFormSubmissionController<VendorsGroupFormFields>();
  const { formatMessage } = useMelioIntl();
  const { showMessage } = useSystemMessage();
  const { mutateAsync: updateVendorsGroup, isLoading: isUpdatedingVendorsGroup } = useUpdateVendorGroup();
  const { data: vendorGroups, isLoading: isLoadingVendorsGroup } = useVendorGroups();
  const { isExtraSmallScreen } = useBreakpoint();
  const { pageSize } = usePayDashboardPagination();

  const paginationResponse = usePaginatedVendors({
    cacheTime: 0,
    params: {
      limit: pageSize,
      sort: [{ field: 'name', order: 'asc' }],
      expand: 'groups',
    },
  });

  const { isLoading: isLoadingVendors } = paginationResponse;

  const vendorsGroup = vendorGroups?.find(({ id }) => id === vendorsGroupId);

  const handleSubmit = async (vendorsGroup: VendorsGroupFormFields) => {
    try {
      await updateVendorsGroup({ vendorGroupId: vendorsGroupId, fields: vendorsGroup });
      showMessage({
        title: formatMessage('activities.editVendorsGroupModal.toasts.success', { name: vendorsGroup.name }),
        type: 'success',
        dataTestId: 'edit-vendors-group-success',
      });
      onClose();
    } catch (e) {
      showMessage({
        title: formatMessage('activities.editVendorsGroupModal.toasts.error', { name: vendorsGroup.name }),
        type: 'error',
        dataTestId: 'edit-vendors-group-error',
      });
    }
  };

  const buttonProps = isExtraSmallScreen ? { padding: 'xs' } : {};

  return (
    <Modal
      isOpen={isOpen}
      isLoading={isLoadingVendorsGroup || isLoadingVendors}
      onClose={onClose}
      data-testid="edit-vendors-group-modal"
      header={formatMessage('activities.editVendorsGroupModal.header')}
      footer={
        <Group justifyContent="space-between">
          <Button
            variant="secondary"
            onClick={onClose}
            label={formatMessage('activities.editVendorsGroupModal.buttons.secondary')}
            data-testid="modal-btn-cancel"
            {...buttonProps}
          />
          <Group>
            <Button
              variant="critical-secondary"
              label={formatMessage('activities.editVendorsGroupModal.buttons.delete')}
              data-testid="modal-btn-delete"
              onClick={() => onDeleteVendorsGroupClick(vendorsGroupId)}
              {...buttonProps}
            />
            <Button
              {...submitButtonProps}
              variant="primary"
              label={formatMessage('activities.editVendorsGroupModal.buttons.primary')}
              data-testid="modal-btn-save"
              {...buttonProps}
            />
          </Group>
        </Group>
      }
    >
      <VendorsGroupForm
        onSubmissionStateChange={onSubmissionStateChange}
        handleSubmit={handleSubmit}
        isSaving={isUpdatedingVendorsGroup}
        vendorsGroup={vendorsGroup}
      />
    </Modal>
  );
};
