import { Vendor } from '@melio/platform-api';

const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const DELIMITERS_REGEX = /[\\/,]/;

const isEmail = (value: string) => EMAIL_REGEX.test(value);

export const isContainsEmail = (value: string) =>
  value
    .trim()
    .split(' ')
    .some((word) => isEmail(word));

const whitelist = [
  'Talk2Pro',
  'Verizon',
  'Linkedin',
  'Quickbooks',
  'Zoom',
  'Amazon',
  'Walmart',
  'Youtube',
  'Salesforce',
  'Fedex',
  'Staples',
  'Divvy',
  'Google',
  'Meta',
];

export const isWhitelist = (value: string) =>
  whitelist.some((companyName) => companyName.toLowerCase().trim() === value.toLowerCase().trim());

const bankNames = [
  'Chase',
  'JPMorgan Chase',
  'Wells Fargo',
  'Chime',
  'PNC',
  'Capital One',
  'Goldman Sachs',
  'American Express',
  'AMEX',
  'Charles Schwab',
  'Truist',
  'Morgan Stanely',
  'USAA',
  'Ally',
  'Citibank',
  'TDBank',
  'Bank',
  'Credit Union',
];

const splitInputByDelimiters = (value: string) =>
  value
    .split(DELIMITERS_REGEX)
    .map((_value) => _value.trim())
    .filter(Boolean);

export const isBank = (value: string) => {
  const inputs = splitInputByDelimiters(value.toLowerCase());
  const words = new Set(inputs.join(' ').split(' '));

  return bankNames.some((value) => {
    const bankName = value.toLowerCase();
    if (bankName.split(' ').length > 1) {
      return inputs.some((input) => input.includes(bankName));
    }
    return words.has(bankName);
  });
};

export const isPartialName = (value: string) => value.trim().split(' ').length === 1;

export const validateNotContainsEmail =
  ({ isEnabled, isManaged }: { isEnabled: boolean; isManaged?: boolean }) =>
  (value: string | undefined) => {
    if (!isEnabled || !value || isManaged || isWhitelist(value)) {
      return true;
    }
    return !isContainsEmail(value);
  };

export const validateUniqueNameForSync = (vendors?: Vendor[], vendorId?: string) => (value: string | undefined) =>
  !vendors?.some((vendor) => {
    const syncName = vendor?.uniqueName || vendor?.name;
    return syncName === value && vendor?.id !== vendorId;
  });
