/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { HStack, Stack } from '@chakra-ui/react';
import { compact } from 'lodash';
import { ActionsDropdownMenu, ActionsDropdownMenuItemProps, Currency, Text, useTheme } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { AccountingPlatform, useBill, useFile, useInboxItems, useScannedInvoice } from '@melio/platform-api';
import { Bill } from '@melio/platform-api';
import { usePermissions } from '@melio/platform-permissions';
import { useConfig, usePartnerFeature } from '@melio/platform-provider';
import { FileType } from '@melio/platform-provider';
import { forwardRef, useSystemMessage } from '@melio/platform-utils';

import { useUploadFile } from '@/hooks/files.hooks';
import { useRouter } from '@/hooks/router.hooks';
import { useConfirmationBarSubmissionController } from '@/hooks/useConfirmationBar';
import { useDisclosure } from '@/hooks/useDisclosure';
import { FormattedMessage, usePlatformIntl } from '@/translations/Intl';
import { PayDashboardTabs } from '@/types/payDashboard.types';
import { MarkAsPaidDialog } from '@/widgets/pay-dashboard/bill-details/components/MarkAsPaidDialog';
import { ScannedInvoiceEmailDetails } from '@/widgets/pay-dashboard/scanned-invoice-details/components/ScannedInvoiceEmailDetails';
import { BillDetailsForm, BillDetailsFormFields } from './components/BillDetailsForm';
import { BillFile } from './components/BillFile';
import { DeleteBillDialog } from './components/DeleteBillDialog';

const OpenBalanceSection = ({ balance }: { balance: number }) => {
  const theme = useTheme();

  return (
    <HStack
      justifyContent="space-between"
      alignItems="center"
      p="32px 40px"
      backgroundColor={theme.colors.global.neutral['200']}
      borderTop="basic.light"
    >
      <Text textStyle="heading2Semi">
        <FormattedMessage id={'widgets.billDetails.totalAmount'} />
      </Text>
      <Text textStyle="heading2Semi" as="div">
        <Currency data-testid="bill-details-currency-amount" value={balance} />
      </Text>
    </HStack>
  );
};

type BillDetailsProps = {
  bill: Bill;
  withPayment?: boolean;
  activeAccountingPlatform?: AccountingPlatform;
  noBorder?: boolean;
};

const SUBMIT_EVENT_NAME = 'PayDashboardInboxBillEdit';

export const BillDetails = forwardRef<BillDetailsProps>(
  ({ bill, withPayment, activeAccountingPlatform, noBorder }, ref) => {
    const { formatMessage } = usePlatformIntl();
    const { showMessage } = useSystemMessage();
    const { createTrackHandler, track } = useAnalytics();
    const { replaceToPayDashboardTab, goToEditBill } = useRouter();
    const { can } = usePermissions();
    const [isMarkAsPaidEnabled] = usePartnerFeature('MarkAsPaid', false);

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const theme = useTheme();

    const config = useConfig();

    const {
      isOpen: isDeleteBillDialogOpen,
      onOpen: onDeleteBillModalOpen,
      onClose: onDeleteBillModalClose,
    } = useDisclosure();
    const {
      isOpen: isMarkAsPaidBillDialogOpen,
      onOpen: onMarkAsPaidBillModalOpen,
      onClose: onMarkAsPaidBillModalClose,
    } = useDisclosure();
    const trackActionChose = createTrackHandler('PayDashboardInboxAction', 'Chose');
    const hasAccountingPlatform = !!activeAccountingPlatform;

    const fileUploader = useUploadFile();

    const { update: updateBill, markAsPaid, isMutating: isUpdating } = useBill({ id: bill.id, enabled: false });
    const { data: scannedInvoice } = useScannedInvoice({
      id: bill.scannedInvoiceId!,
      enabled: !!bill.scannedInvoiceId,
    });
    const { data: fileOnBill } = useFile({ id: bill.invoice.fileId || undefined });
    const { refetch: refetchInboxItems } = useInboxItems({ enabled: false });
    const hasEditBillPermission = can({
      subject: 'bill',
      action: 'update',
      subjectData: {
        createdById: bill.createdById,
        vendor: {
          createdById: bill.vendor?.createdById,
          managedBy: bill.vendor?.managedBy,
        },
      },
    });
    const hasDeleteBillAndPaymentsPermission =
      can({
        subject: 'bill',
        action: 'delete',
        subjectData: {
          createdById: bill.createdById,
          vendor: {
            createdById: bill.vendor?.createdById,
            managedBy: bill.vendor?.managedBy,
          },
        },
      }) &&
      can({ subject: 'payment', action: 'delete' }) && // No subject data?
      can({
        subject: 'billSubscription',
        action: 'cancel',
        subjectData: {
          createdById: bill.createdById,
          vendor: {
            createdById: bill.vendor?.createdById,
            managedBy: bill.vendor?.managedBy,
          },
        },
      });

    const formDefaultValues = {
      vendorId: bill.vendor?.id || '',
      totalAmount: `${bill.amount}`,
      dueDate: bill.dueDate || null,
      billNumber: fileUploader.data?.billDetails?.invoiceNumber || bill.invoice.number || '',
      noteToSelf: bill.note || '',
      categoryId: bill.categoryId || '',
    };

    const abortUpload = () => {
      fileUploader.reset();
      setSubmitButtonState({ isLoading: false });
    };

    const onSubmitMarkAsPaid = async (accountingPlatformPaymentAccountId?: string) => {
      await markAsPaid({ isPaid: true, amount: bill.amount, accountingPlatformPaymentAccountId });
    };

    const {
      isOpen: isEditMode,
      showConfirmationBar,
      onSubmissionStateChange,
      setSubmitButtonState,
      reset,
      isFormDirty,
    } = useConfirmationBarSubmissionController<BillDetailsFormFields>(
      {
        submitButtonProps: { text: formatMessage('widgets.confirmation.billDetails.primary') },
        cancelButtonProps: { text: formatMessage('widgets.confirmation.billDetails.secondary') },
        defaultValues: formDefaultValues,
        eventProps: {
          primaryButton: {
            eventName: SUBMIT_EVENT_NAME,
            action: 'Submitted',
            properties: { BillId: bill.id },
          },
          SecondaryButton: {
            eventName: SUBMIT_EVENT_NAME,
            action: 'Canceled',
            properties: { BillId: bill.id },
          },
        },
      },
      { resetOnSecondary: true, onCancelCb: abortUpload },
    );

    useEffect(() => abortUpload, []);

    const handleFileUpload = async (fileList: FileList | null) => {
      if (!fileList?.[0]) return;

      if (fileList[0].size > config.settings.fileSizeLimit)
        return showMessage({
          type: 'error',
          title: formatMessage('widgets.billDetails.file.validation.fileSize'),
        });

      const fileType = fileList[0].type?.split('/')[1] as FileType;
      if (fileType && !config.settings.fileAllowedFormats.includes(fileType))
        return showMessage({
          type: 'error',
          title: formatMessage('widgets.billDetails.file.validation.fileFormat'),
        });

      setSubmitButtonState({ isLoading: true });

      await fileUploader.upload(fileList[0]);

      setSubmitButtonState({ isLoading: false });
    };

    const handleFormSubmit = async (data: BillDetailsFormFields) => {
      try {
        if (!isFormDirty(data) && !fileUploader.isModified) return;

        setSubmitButtonState({ isLoading: true });

        const newAmount = parseFloat((+data.totalAmount).toFixed(2));

        const getFileValueToUpdate = () => {
          if (fileUploader.data === null) {
            return null;
          }
          return fileUploader.data?.fileInfo?.id || fileOnBill?.id;
        };

        await updateBill({
          amount: newAmount,
          balance: newAmount,
          dueDate: data.dueDate?.toISOString(),
          invoice: {
            number: data.billNumber,
            fileId: getFileValueToUpdate(),
          },
          vendorId: data.vendorId,
          note: data.noteToSelf,
          categoryId: data.categoryId,
        });

        refetchInboxItems();

        showMessage({
          type: 'informative',
          title: formatMessage('widgets.billDetails.toast.success'),
        });
        track('PayDashboardBillUpdated', 'Viewed', {
          BillId: bill.id,
        });
      } catch {
        reset?.(formDefaultValues);
        showMessage({
          type: 'error',
          title: formatMessage('widgets.billDetails.toast.error'),
        });
      } finally {
        setSubmitButtonState({ isLoading: false });
        showConfirmationBar(false);
      }
    };

    const handleEditClicked = () => {
      trackActionChose({ ActionType: 'Edit' });
      goToEditBill({ id: bill.id });
    };

    const handleDeleteClicked = () => {
      trackActionChose({ ActionType: 'remove' });
      onDeleteBillModalOpen();
    };

    const handleDeleteSuccess = () => {
      refetchInboxItems();
      replaceToPayDashboardTab(PayDashboardTabs.Inbox);
    };

    const handleMarkAsPaidClick = () => {
      trackActionChose({
        ActionType: 'mark-as-paid',
        isQB: hasAccountingPlatform,
        totalAmount: bill.amount,
      });
      onMarkAsPaidBillModalOpen();
    };

    const actionMenuItems = compact([
      isMarkAsPaidEnabled &&
        hasEditBillPermission && {
          label: formatMessage('widgets.billDetails.menu.items.markAsPaid'),
          onClick: handleMarkAsPaidClick,
          dataTestId: 'bill-details-menu-mark-as-paid',
        },
      hasEditBillPermission && {
        label: formatMessage('widgets.billDetails.menu.items.edit'),
        onClick: handleEditClicked,
        dataTestId: 'bill-details-menu-edit',
      },
      hasDeleteBillAndPaymentsPermission && {
        label: formatMessage('widgets.billDetails.menu.items.delete'),
        onClick: handleDeleteClicked,
        variant: 'critical' as ActionsDropdownMenuItemProps['variant'],
        dataTestId: 'bill-details-menu-delete',
      },
    ]);

    return (
      <>
        <Stack
          flexDirection="column"
          ref={ref}
          border={noBorder ? 'none' : 'basic.light'}
          borderRadius={theme.borderRadii.global['200']}
          overflow="hidden"
          spacing={0}
          gridGap={0}
        >
          <Stack flexDirection="column" p={{ xs: '24px', s: '32px 40px' }} gridGap="24px" spacing={0}>
            <HStack justifyContent={'space-between'} alignItems={'center'} width={'full'}>
              <Text textStyle={'heading2Semi'}>{formatMessage('widgets.billDetails.title')}</Text>
              {!withPayment && !isEditMode && actionMenuItems.length ? (
                <ActionsDropdownMenu
                  data-testid="bill-details-menu"
                  label={formatMessage('widgets.billDetails.menu.button')}
                  items={actionMenuItems}
                  isOpen={isMenuOpen}
                  onOpenChange={setIsMenuOpen}
                />
              ) : null}
            </HStack>
            <Stack flexDirection={{ xs: 'column-reverse', s: 'row' }} gridGap={'32px'} alignItems="flex-start">
              <BillDetailsForm
                editMode={isEditMode}
                activeAccountingPlatform={activeAccountingPlatform}
                isDisabled={fileUploader.isUploading}
                defaultValues={formDefaultValues}
                onSubmissionStateChange={onSubmissionStateChange}
                onSubmit={handleFormSubmit}
              />
              <BillFile
                editMode={isEditMode}
                file={fileOnBill}
                fileUploadedByUser={fileUploader.data}
                onFileUploaded={handleFileUpload}
                isProcessingFile={fileUploader.isUploading}
                onFileDeleted={fileUploader.reset}
              />
            </Stack>
          </Stack>
          {!withPayment && bill.balance && <OpenBalanceSection balance={bill.balance} />}
        </Stack>
        {scannedInvoice?.inboxEmail && <ScannedInvoiceEmailDetails inboxEmail={scannedInvoice.inboxEmail} />}
        {isMarkAsPaidEnabled && (
          <MarkAsPaidDialog
            isOpen={isMarkAsPaidBillDialogOpen}
            onClose={onMarkAsPaidBillModalClose}
            onMarkAsPaidSubmit={onSubmitMarkAsPaid}
            isLoading={isUpdating}
            hasAccountingPlatform={hasAccountingPlatform}
            amount={bill.amount}
          />
        )}
        <DeleteBillDialog
          billId={bill.id}
          isOpen={isDeleteBillDialogOpen}
          onClose={onDeleteBillModalClose}
          onSuccess={handleDeleteSuccess}
        />
      </>
    );
  },
);
