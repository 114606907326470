/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useBreakpointValue } from '@chakra-ui/react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  Bill,
  InboxItemPaymentTypeEnum,
  InboxItemScannedInvoiceTypeEnum,
  Payment,
  PaymentRequest,
  useFundingSources,
  useInboxItems,
  usePaymentIntent,
} from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';

import { useRouter } from '@/hooks/router.hooks';
import { useActiveScreen } from '@/hooks/useActiveScreen';
import { ActiveFlowEnum, ScreensEnum } from '@/store/app/app.types';
import { SelectedCard } from '@/store/PayDashboard/PayDashboard.types';
import {
  filterByVendorIdState,
  payDashboardForceSelectFirstSelector,
  payDashboardItemSelectionsSelector,
  payDashboardQuerySearchSelector,
  payDashboardSelectedCardSelector,
} from '@/store/PayDashboard/PayDashboards.model';
import { vendorsState } from '@/store/Vendors/Vendors.model';
import {
  GroupItem,
  isBillGroupItem,
  isPaymentGroupItem,
  isPaymentRequestGroupItem,
  isScannedInvoiceGroupItem,
  PayDashboardTabs,
} from '@/types/payDashboard.types';
import {
  createUnfilteredPayDashboardGroups,
  filterPayDashboardGroups,
  getInboxItemTypeByIdPrefix,
} from '@/utils/payDashboard.utils';
import { useIsMobile } from './useIsMobile';

const getItemSelectionCard = (
  item: GroupItem,
): Pick<SelectedCard, 'selectedInboxItemType' | 'selectedInboxItemId'> | undefined => {
  if (isScannedInvoiceGroupItem(item)) {
    return {
      selectedInboxItemType: 'scannedInvoice',
      selectedInboxItemId: item.scannedInvoice.id,
    };
  } else if (isPaymentGroupItem(item)) {
    return {
      selectedInboxItemType: 'payment',
      selectedInboxItemId: item.payment.id,
    };
  } else if (isBillGroupItem(item)) {
    return {
      selectedInboxItemType: 'bill',
      selectedInboxItemId: item.bill.id,
    };
  } else if (isPaymentRequestGroupItem(item)) {
    return {
      selectedInboxItemType: 'paymentRequest',
      selectedInboxItemId: item.paymentRequest.id,
    };
  }
};

const useSetInboxItemSelectedCard = () => {
  const setSelectedCard = useSetRecoilState(payDashboardSelectedCardSelector);

  return useCallback(
    (item: GroupItem) => {
      const selections = getItemSelectionCard(item);
      if (selections) {
        setSelectedCard(selections);
      }
    },
    [setSelectedCard],
  );
};

export const useSelectFirstCardFromGroup = (groups: { items: GroupItem[] }[]) => {
  const shouldSelectFirstItemMobile = useBreakpointValue({ xs: false, s: true }, { ssr: false } as never);
  const { inboxItemId } = useParams();
  const setSelectedCard = useSetRecoilState(payDashboardSelectedCardSelector);
  const setInboxItemSelectedCard = useSetInboxItemSelectedCard();
  const [forceSelectCard, setForceSelectCard] = useRecoilState(payDashboardForceSelectFirstSelector);
  const lastSelectedItem = useRef<GroupItem>();

  useEffect(() => {
    // if we force select skipping stright to selecting
    // else - we need to check if there is a selected item in the group
    if (forceSelectCard) {
      setForceSelectCard(false);
      lastSelectedItem.current = undefined;
    } else if (inboxItemId || !groups.length || !shouldSelectFirstItemMobile) {
      return;
    }

    let foundFirstItem: boolean = false;
    groups.forEach((group) => {
      if (foundFirstItem) {
        return;
      }
      const firstItem = group.items.find((item) => !!item);
      if (firstItem) {
        foundFirstItem = true;
        lastSelectedItem.current = firstItem;
        setInboxItemSelectedCard(firstItem as GroupItem);
      }
    });

    if (!foundFirstItem) {
      setSelectedCard({});
    }

    return () => {
      setSelectedCard({});
    };
  }, [groups, inboxItemId, forceSelectCard, shouldSelectFirstItemMobile]);
};

export const usePayDashboardHandleNavigation = (activeTab: PayDashboardTabs) => {
  useActiveScreen(ScreensEnum.payDashboard, ActiveFlowEnum.payDashboard);
  const { paymentId, scannedInvoiceId, inboxItemId } = useParams();
  const [selectedCard, setSelectedCard] = useRecoilState(payDashboardSelectedCardSelector);
  const { goToPaySelectedInboxItem } = useRouter();
  const { selectedInboxItemId, selectedInboxItemType } = selectedCard;

  useEffect(() => {
    const inboxItemType = getInboxItemTypeByIdPrefix(inboxItemId);
    if (scannedInvoiceId) {
      // backward compatibility - draft/:scannedInvoiceId
      setSelectedCard({
        selectedInboxItemType: InboxItemScannedInvoiceTypeEnum.ScannedInvoice,
        selectedInboxItemId: scannedInvoiceId,
      });
    } else if (paymentId) {
      // backward compatibility - :paymentIntentId/payment/:paymentId
      setSelectedCard({
        selectedInboxItemType: InboxItemPaymentTypeEnum.Payment,
        selectedInboxItemId: paymentId,
      });
    } else if (inboxItemType) {
      setSelectedCard({
        selectedInboxItemType: inboxItemType,
        selectedInboxItemId: inboxItemId,
      });
    }

    return () => {
      setSelectedCard({});
    };
  }, [inboxItemId]);

  useEffect(() => {
    const searchQueryString = window.location.search;
    if (selectedInboxItemId && selectedInboxItemType) {
      goToPaySelectedInboxItem(activeTab, selectedInboxItemId, searchQueryString, { replace: true });
    }
  }, [selectedInboxItemId, selectedInboxItemType]);
};

const isMultiSelectableGroupItem = (item: GroupItem) => isBillGroupItem(item);

export const useMultiSelect = () => {
  const [isBatchPaymentsEnabled] = useDevFeature(FeatureFlags.BatchPayments, false);
  const [itemCheckboxSelections, setItemCheckboxSelections] = useRecoilState(payDashboardItemSelectionsSelector);

  const isItemCheckboxSelected = (inboxItemId: string) => itemCheckboxSelections.find((id) => id === inboxItemId);

  const isMobile = useIsMobile();

  const getIsMultiSelectAllowed = (item: GroupItem) =>
    isBatchPaymentsEnabled && !isMobile && isMultiSelectableGroupItem(item);

  const onClickDuringMultiSelect = (item: GroupItem) => {
    if (!getIsMultiSelectAllowed(item)) return;

    const itemId = getItemSelectionCard(item)?.selectedInboxItemId;
    if (itemId && !isItemCheckboxSelected(itemId)) {
      setItemCheckboxSelections([...itemCheckboxSelections, itemId]);
    }
  };

  const isMultiSelectStarted = itemCheckboxSelections.length > 0;

  return { onClickDuringMultiSelect, isMultiSelectStarted, getIsMultiSelectAllowed };
};

export const useSelectedGroupItemCard = () => {
  const [selectedCard] = useRecoilState(payDashboardSelectedCardSelector);
  const selectedItemIds = useRecoilValue(payDashboardItemSelectionsSelector);
  const setInboxItemSelectedCard = useSetInboxItemSelectedCard();

  const { onClickDuringMultiSelect, isMultiSelectStarted } = useMultiSelect();

  const isSingleSelected = ({ ...item }: GroupItem) => {
    if (selectedItemIds.length) {
      return false;
    }

    if (isScannedInvoiceGroupItem(item)) {
      return item.scannedInvoice.id === selectedCard.selectedInboxItemId;
    } else if (isPaymentGroupItem(item)) {
      return item.payment.id === selectedCard.selectedInboxItemId;
    } else if (isBillGroupItem(item)) {
      return item.bill.id === selectedCard.selectedInboxItemId;
    } else if (isPaymentRequestGroupItem(item)) {
      return item.paymentRequest.id === selectedCard.selectedInboxItemId;
    }
    return false;
  };

  const onSingleSelect = (item: GroupItem) => {
    setInboxItemSelectedCard(item as GroupItem);
  };

  const onSelect = (item: GroupItem) => (isMultiSelectStarted ? onClickDuringMultiSelect(item) : onSingleSelect(item));

  return {
    isSelected: isSingleSelected,
    onSelect,
  };
};

export const filterPaymentByVendor = ({ payment, vendorId }: { payment: Payment; vendorId: string | null }): boolean =>
  vendorId ? payment.vendorId === vendorId : true;

export const filterBillByVendor = ({ bill, vendorId }: { bill: Bill; vendorId: string | null }): boolean =>
  vendorId ? bill.vendorId === vendorId : true;

export const filterPaymentRequestByVendor = ({
  paymentRequest,
  vendorId,
}: {
  paymentRequest: PaymentRequest;
  vendorId: string | null;
}) => (vendorId ? paymentRequest.vendorId === vendorId : true);

export const usePayDashboardItems = (
  { refetchGroupsOnMount }: { refetchGroupsOnMount: boolean } = { refetchGroupsOnMount: false },
) => {
  const [searchParams] = useSearchParams();
  const vendorId = searchParams.get('vendor');
  const vendors = useRecoilValue(vendorsState);
  const { data: fundingSources = [], isFetching: isFundingSourcesLoading } = useFundingSources();
  const querySearch = useRecoilValue(payDashboardQuerySearchSelector);

  const setVendorId = useSetRecoilState(filterByVendorIdState);

  const {
    isFetching: inboxItemsIsLoading,
    error: inboxItemsError,
    data: inboxItemsData,
    refetch: refetchInboxItems,
  } = useInboxItems({});

  useEffect(() => {
    if (refetchGroupsOnMount) refetchInboxItems();
  }, []);

  const groups = useMemo(() => {
    const unfilteredGroups = createUnfilteredPayDashboardGroups({
      fundingSources,
      vendors,
      inboxItems: inboxItemsData?.data,
    });

    return filterPayDashboardGroups({ groups: unfilteredGroups, querySearch, vendorId });
  }, [fundingSources, vendors, inboxItemsData, querySearch, vendorId]);

  useEffect(() => {
    setVendorId(vendorId);
  }, [setVendorId, vendorId]);

  return {
    isLoading: inboxItemsIsLoading || isFundingSourcesLoading,
    isError: !!inboxItemsError,
    error: inboxItemsError,
    groups,
  };
};

export const usePaydashboardEmptyState: () => boolean = () => {
  const { data: inboxItemsData } = useInboxItems({});
  const [isDashboardEmptyStateEnabled] = useDevFeature(FeatureFlags.DashboardEmptyState, false);

  return isDashboardEmptyStateEnabled && inboxItemsData?.data.length === 0;
};

export const useInboxItemLink = () => {
  const { generatePayDashboardTabLink } = useRouter();
  const { inboxItemId } = useParams();
  const selectedPaymentIntentId = inboxItemId && inboxItemId.startsWith('pymntintnt_') ? inboxItemId : undefined;
  const { data: paymentIntentData, isLoading: isPaymentIntentLoading } = usePaymentIntent({
    id: selectedPaymentIntentId,
    enabled: !!selectedPaymentIntentId,
  });

  return {
    inboxItemLink: paymentIntentData
      ? generatePayDashboardTabLink(PayDashboardTabs.Inbox, paymentIntentData.billInfo.id)
      : null,
    isLoading: isPaymentIntentLoading,
  };
};
