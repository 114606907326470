import { useMelioQueryClient } from '@melio/api-client';
import { ApiKeys, VendorGroup, VendorGroupsApiClient } from '@melio/platform-api-axios-client';
// eslint-disable-next-line no-restricted-imports
import { useMutation } from 'react-query';

export const useDeleteVendorGroup = () => {
  const queryClient = useMelioQueryClient();

  return useMutation({
    mutationKey: ApiKeys.VendorGroupsApi,
    mutationFn: async ({ vendorGroupId }: { vendorGroupId: VendorGroup['id'] }) => {
      const response = await VendorGroupsApiClient.deleteVendorGroupsVendorGroupId(vendorGroupId);

      return response.data.deleted;
    },
    onSuccess: () => {
      void queryClient.invalidateQueries(ApiKeys.VendorGroupsApi);
    },
  });
};
