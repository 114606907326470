import { useState } from 'react';
import { createSearchParams, useLocation } from 'react-router-dom';
import { compact } from 'lodash';
import { useActivitiesNavigate } from '@melio/ap-activities';
import { isEbill, isFXCurrency, useVendorActions } from '@melio/ap-domain';
import {
  ActionsDropdownMenu,
  ActionsDropdownMenuProps,
  Button,
  Group,
  useBreakpoint,
  useFormSubmissionController,
} from '@melio/penny';
import { OriginFlow, useAnalytics } from '@melio/platform-analytics';
import { useBills, Vendor } from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { useSubscriptionFeature } from '@melio/subscriptions';

import { useRouter } from '@/hooks/router.hooks';
import { useAutoFocusMoreActionsButton } from './hooks/useAutoFocusMoreActionsButton';

export const VendorDrawerFooter = ({
  vendor,
  onDeleteClick,
  submitButtonProps,
  cancelButtonProps,
  isEditMode,
  onEditClick,
  onCancelClick,
}: {
  vendor: Vendor;
  onDeleteClick: (vendor: Vendor) => void;
  submitButtonProps: ReturnType<typeof useFormSubmissionController>['submitButtonProps'];
  cancelButtonProps: ReturnType<typeof useFormSubmissionController>['cancelButtonProps'];
  isEditMode: boolean;
  onEditClick: VoidFunction;
  onCancelClick: VoidFunction;
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { formatMessage } = useMelioIntl();
  const { track } = useAnalytics();
  const { goToAddNewBill, goToNewDashboardTab, goToEBillsSubscription, goToAutoPaymentActivation } = useRouter();
  const vendorActions = useVendorActions(vendor);
  const { pathname, search } = useLocation();
  const { isExtraSmallScreen } = useBreakpoint();
  const { data: vendorBills } = useBills({
    enabled: !!vendor.id,
    params: { search: { 'vendor.id': vendor.id }, shouldFilterPaidBills: true },
    suspense: true,
  });
  const openBalance = vendorBills?.reduce(
    (acc, bill) => acc + (isEbill(bill) && bill.eBillAmountDue != null ? bill.eBillAmountDue : bill.balance),
    0,
  );
  const vendorHasPositiveBalance = !!openBalance && openBalance > 0;

  const { moreActionsButtonRef, focusMoreActions } = useAutoFocusMoreActionsButton();

  const { navigateToBatchPayment, navigateToSchedulePayment, navigateToScheduleNewPayment } = useActivitiesNavigate();
  const { tryUseFeature: tryUseBatchPayments } = useSubscriptionFeature({ featureName: 'batchPayments' });
  const [isPlatformBatchPaymentsEnabled] = useDevFeature<boolean>(FeatureFlags.PlatformBatchPayments, false);

  if (isEditMode) {
    return (
      <Group width="full" justifyContent="space-between">
        <Button
          variant="tertiary"
          data-testid="vendor-btn-edit-cancel"
          label={formatMessage('widgets.confirmation.singleVendor.secondary')}
          onClick={() => {
            onCancelClick();
            setTimeout(focusMoreActions, 0);
          }}
          {...cancelButtonProps}
        />
        <Button
          variant="primary"
          data-testid="vendor-btn-edit-primary"
          label={formatMessage('widgets.confirmation.singleVendor.primary')}
          loadingText={formatMessage('app.loading')}
          {...submitButtonProps}
        />
      </Group>
    );
  }
  const stateParams = {
    vendorId: vendor?.id,
    returnUrl: `${pathname}?${createSearchParams(search).toString()}`,
  };

  const onPayBills = (unpaidBillsIds: string[]) => {
    if (unpaidBillsIds.length === 1) {
      return navigateToSchedulePayment({ billId: unpaidBillsIds[0], returnUrl: pathname });
    }

    tryUseBatchPayments({
      onFeatureIsEligible: () => navigateToBatchPayment({ billIds: unpaidBillsIds, returnUrl: pathname }),
    });
  };
  const numOfUnpaidBills = Number(vendorBills?.map((bill) => bill.id).length);
  const shouldDisableBatchPaymentsCta =
    numOfUnpaidBills > 1 && (!isPlatformBatchPaymentsEnabled || isFXCurrency(vendor.currency));
  const actionItems: ActionsDropdownMenuProps['items'] = compact([
    vendorActions.newPayment &&
      vendorBills &&
      vendorBills.length > 0 &&
      vendorHasPositiveBalance && {
        label: formatMessage('widgets.singleVendor.actions.payBalance'),
        disabled: { isDisabled: shouldDisableBatchPaymentsCta },
        onClick: () => {
          track('Vendor', 'Click', { Cta: 'pay-balance' });

          const unpaidBillsIds = vendorBills.map((bill) => bill.id);

          return isExtraSmallScreen && unpaidBillsIds.length > 1
            ? goToNewDashboardTab(`bills?search=${vendor.name}`)
            : onPayBills(unpaidBillsIds);
        },
        dataTestId: 'vendor-btn-pay-balance',
      },
    vendorActions.newBill && {
      label: formatMessage('widgets.singleVendor.actions.newPayment'),
      onClick: () => {
        track('Vendor', 'Click', { Cta: 'new-payment' });
        navigateToScheduleNewPayment({ ...stateParams, origin: OriginFlow.VendorsFlow });
      },
      dataTestId: 'vendor-btn-new-payment',
    },
    vendorActions.newBill && {
      label: formatMessage('widgets.singleVendor.actions.addBill'),
      onClick: () => {
        track('Vendor', 'Click', { Cta: 'more-add-bill' });
        goToAddNewBill({ ...stateParams, originFlow: OriginFlow.VendorsFlow, waitingForMessage: true });
      },
      dataTestId: 'vendor-menu-add-bill',
    },
    vendorActions.eBillSubscribe && {
      label: formatMessage('widgets.singleVendor.actions.eBillSubscibe'),
      onClick: () => {
        track('Vendor', 'Click', { Cta: 'more-subscribe-to-ebill' });
        goToEBillsSubscription(vendor.id);
      },
      dataTestId: 'vendor-menu-ebill-subscribe',
    },
    vendorActions.autoPayActivation && {
      label: formatMessage('widgets.singleVendor.actions.autoPayActivation'),
      onClick: () => {
        track('Vendor', 'Click', { Cta: 'more-autopay-set-up', AutoPayStatus: vendor.autoPayStatus });
        goToAutoPaymentActivation(vendor.id);
      },
      dataTestId: 'vendor-menu-autoPay-activation',
    },
    vendorActions.edit && {
      label: formatMessage('widgets.singleVendor.actions.editVendor'),
      onClick: onEditClick,
      dataTestId: 'vendor-menu-edit',
    },
    vendorActions.delete && {
      label: formatMessage('widgets.singleVendor.actions.deleteVendor'),
      onClick: () => {
        onDeleteClick(vendor);
        track('Vendor', 'Click', { Cta: 'more-delete-vendor' });
      },
      variant: 'critical' as const,
      dataTestId: 'vendor-menu-delete',
    },
  ]);

  const handleOpenChange = (isOpen: boolean) => {
    if (isOpen) {
      track('Vendor', 'Click', { Cta: 'more-options' });
    }
    setIsMenuOpen(isOpen);
  };

  const [primaryItem, ...actionMenuItems] = actionItems;
  const hasMenuItems = actionMenuItems.length > 0;

  return (
    <Group
      width="full"
      spacing={isExtraSmallScreen ? 's' : 'm'}
      justifyContent={hasMenuItems ? 'space-between' : 'flex-end'}
    >
      {hasMenuItems ? (
        <Group.Item grow={isExtraSmallScreen ? 1 : undefined}>
          <ActionsDropdownMenu
            ref={moreActionsButtonRef}
            isFullWidthTrigger
            label={formatMessage('widgets.singleVendor.button.more')}
            items={actionMenuItems}
            data-testid="vendor-menu"
            isOpen={isMenuOpen}
            onOpenChange={handleOpenChange}
          />
        </Group.Item>
      ) : null}

      {primaryItem ? (
        <Group.Item grow={isExtraSmallScreen ? 1 : undefined}>
          <Button
            variant="primary"
            isFullWidth
            data-testid={primaryItem.dataTestId}
            onClick={() => primaryItem.onClick?.()}
            label={primaryItem.label}
            isDisabled={primaryItem.disabled?.isDisabled}
          />
        </Group.Item>
      ) : null}
    </Group>
  );
};
