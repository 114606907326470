import {
  Button,
  ButtonProps,
  Container,
  Divider,
  Group,
  IconButton,
  Menu,
  RadioGroup,
  StatusIndicator,
  Text,
  useBreakpoint,
} from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { useEffect, useRef, useState } from 'react';

import { FilterOption } from './types';

type FooterButton<T> = {
  label: string;
  onClick: (id: T) => void;
  variant: ButtonProps['variant'];
  disabledOnDefault?: boolean;
  getAriaLabel?: (selectedFilterLabel: string) => string;
  testId?: string;
};
type Props<T> = {
  options: FilterOption<T>[];
  activeFilter: T;
  defaultFilter: T;
  title: string;
  onChange: (id: T) => void;
  footerRightButton?: FooterButton<T>;
  footerLeftButton?: FooterButton<T>;
};

export const Filter = <T extends string>({
  options,
  activeFilter,
  defaultFilter,
  title,
  onChange,
  footerRightButton,
  footerLeftButton,
}: Props<T>) => {
  const { formatMessage } = useMelioIntl();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [filter, setFilter] = useState<T>(defaultFilter);
  const label = formatMessage('filter.button.text');
  const menuButtonRef = useRef<HTMLDivElement>(null);
  const { isExtraSmallScreen } = useBreakpoint();

  useEffect(() => {
    if (filter) {
      menuButtonRef.current?.focus();
    }
  }, [filter]);

  const onApply = () => {
    onChange(filter);
    handleOpenMenuChange(false);
  };

  const handleOpenMenuChange = (isOpen: boolean) => {
    setFilter(activeFilter);
    setIsMenuOpen(isOpen);
  };

  const renderOptions = () =>
    options.map(({ id, label, status }, index) => {
      const isSelected = activeFilter === id;
      return {
        label: (
          <Container
            data-testid={`filter-item-${id}`}
            key={`${id}.${index}`}
            paddingY="s"
            paddingLeft="xs"
            width="fit-content"
          >
            <Group spacing="xs">
              {status && !isSelected ? (
                <Group>
                  <StatusIndicator status={status}>
                    <Container paddingRight="xs">
                      <Text textStyle="body3Semi" color="global.neutral.1000">
                        {label}
                      </Text>
                    </Container>
                  </StatusIndicator>
                </Group>
              ) : (
                <Text textStyle="body3Semi" color="global.neutral.1000">
                  {label}
                </Text>
              )}
              {id === defaultFilter && (
                <Text textStyle="body3Semi" color="global.neutral.800">
                  {formatMessage('filter.item.default')}
                </Text>
              )}
            </Group>
          </Container>
        ),
        value: id,
        ariaLabel: label,
      };
    });

  const renderMenuOptions = () => (
    <RadioGroup
      value={filter}
      variant="vertical"
      onChange={(e) => setFilter(e.target.value as T)}
      options={renderOptions()}
      gap="none"
      paddingX="m"
    />
  );

  const renderTrigger = () => (
    <IconButton
      icon="filter"
      variant="primary"
      size="large"
      aria-label={label}
      data-testid="filter-trigger"
      status={defaultFilter !== activeFilter ? 'informative' : undefined}
    />
  );

  const renderMenuFooter = () => (
    <Container>
      <Divider />
      <Container data-testid="filter-footer-menu" paddingX="s" paddingY="s">
        <Group justifyContent={footerLeftButton ? 'space-between' : 'flex-end'} alignItems="center">
          {footerLeftButton ? (
            <Button
              label={footerLeftButton.label}
              variant={footerLeftButton.variant}
              aria-label={footerLeftButton.getAriaLabel?.(options.find(({ id }) => id === filter)?.label ?? '')}
              data-testid={footerLeftButton.testId}
              isDisabled={footerLeftButton.disabledOnDefault && filter === defaultFilter}
              onClick={() => footerLeftButton.onClick(filter)}
              {...(isExtraSmallScreen ? { padding: 'xs' } : {})}
            />
          ) : null}

          <Group>
            {footerRightButton ? (
              <Button
                label={footerRightButton.label}
                variant={footerRightButton.variant}
                aria-label={footerRightButton.getAriaLabel?.(options.find(({ id }) => id === filter)?.label ?? '')}
                data-testid={footerRightButton.testId}
                isDisabled={footerRightButton.disabledOnDefault && filter === defaultFilter}
                onClick={() => footerRightButton.onClick(filter)}
                {...(isExtraSmallScreen ? { padding: 'xs' } : {})}
              />
            ) : null}

            <Button
              label={formatMessage('filter.button.apply')}
              onClick={onApply}
              data-testid="filter-apply"
              {...(isExtraSmallScreen ? { padding: 'xs' } : {})}
            />
          </Group>
        </Group>
      </Container>
    </Container>
  );

  return (
    <Menu
      ref={menuButtonRef}
      data-testid="filter-menu"
      trigger={renderTrigger()}
      onOpenChange={handleOpenMenuChange}
      isOpen={isMenuOpen}
      title={title}
    >
      {renderMenuOptions()}
      {renderMenuFooter()}
    </Menu>
  );
};
